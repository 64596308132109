import {
  Box,
  Card,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { customCurrencyFormat, DropDownIcon } from "../../utils";
import Scrollbar from "../generic/scrollBar";
import HorizontalScrollbar from "../generic/scrollBarX";

const LowerDetailRow = ({
  name = "-",
  forecast = 0,
  actual = 0,
  ytdAverage = 0,
  bracket = false,
}) => {
  return (
    <Tr>
      <Td
        border="2px solid"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {name}
      </Td>
      <Td
        textAlign="right"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? forecast
            ? `(${forecast})`
            : `(${forecast})`
          : forecast
          ? `${forecast}`
          : `${forecast}`}
      </Td>
      <Td
        textAlign="right"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? actual
            ? `(${actual})`
            : `(${actual})`
          : actual
          ? `${actual}`
          : `${actual}`}
      </Td>
      <Td
        textAlign="right"
        backgroundColor={"#F6F8FE"}
        fontWeight={"bold"}
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? ytdAverage
            ? `(${ytdAverage})`
            : `(${ytdAverage})`
          : ytdAverage
          ? `${ytdAverage}`
          : `${ytdAverage}`}
      </Td>
    </Tr>
  );
};

const DetailRow = ({
  name = "-",
  forecast = 0,
  actual = 0,
  ytdAverage = 0,
  bracket = false,
  vendor = false,
  isOpen,
  expenseSetter,
  expenseType,
  vendorExpenseSetter,
  vendorExpense,
  ytdEmpty = false,
}) => {
  return (
    <Tr>
      <Td border="2px solid" borderColor="gray.200" p={"7px 20px"}>
        {name}
      </Td>
      <Td
        textAlign="right"
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {bracket
          ? forecast
            ? `(${forecast})`
            : `(${forecast})`
          : forecast
          ? `${forecast}`
          : `${forecast}`}
      </Td>
      {!vendor ? (
        <Td
          textAlign="right"
          border="2px solid"
          borderColor="gray.200"
          p={"7px 20px"}
        >
          {bracket
            ? actual
              ? `(${actual})`
              : `(${actual})`
            : actual
            ? `${actual}`
            : `${actual}`}
        </Td>
      ) : (
        <Td
          cursor={"pointer"}
          _hover={{ bg: "#97B1FF" }}
          onClick={() => {
            isOpen(true);
            expenseSetter(expenseType);
            vendorExpenseSetter(vendorExpense);
          }}
          textAlign="right"
          border="2px solid"
          borderColor="gray.200"
          p={"7px 20px"}
        >
          {bracket
            ? actual
              ? `(${actual})`
              : `(${actual})`
            : actual
            ? `${actual}`
            : `${actual}`}
        </Td>
      )}
      <Td
        textAlign="right"
        border="2px solid"
        borderColor="gray.200"
        p={"7px 20px"}
      >
        {!ytdEmpty
          ? bracket
            ? ytdAverage
              ? `(${ytdAverage})`
              : `(${ytdAverage})`
            : ytdAverage
            ? `${ytdAverage}`
            : `${ytdAverage}`
          : ""}
      </Td>
    </Tr>
  );
};

const PopulateCashTable = ({ data }) => {
  return (
    <Tbody>
      {data &&
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).map((outerKey) =>
          Object.entries(data[outerKey]).map(([innerKey, value]) =>
            innerKey === "ratio" ? (
              <DetailRow
                key={"ratio"}
                name={"Expense Cover Ratio"}
                forecast={customCurrencyFormat(
                  value.earliest_ratio,
                  false,
                  true,
                  false
                )}
                actual={customCurrencyFormat(
                  value.latest_ratio,
                  false,
                  true,
                  false
                )}
                ytdEmpty={true}
              />
            ) : innerKey === "Total Movement" ? (
              <LowerDetailRow
                key={innerKey}
                name={innerKey}
                forecast={customCurrencyFormat(
                  value.earliest_balance,
                  false,
                  true
                )}
                actual={customCurrencyFormat(value.latest_balance, false, true)}
                ytdAverage={customCurrencyFormat(
                  value.total_movement,
                  false,
                  true
                )}
              />
            ) : outerKey === "credit_cards" ? (
              <DetailRow
                key={innerKey}
                name={innerKey}
                forecast={customCurrencyFormat(
                  value.earliest_balance,
                  false,
                  true
                )}
                actual={customCurrencyFormat(value.latest_balance, false, true)}
                ytdAverage={customCurrencyFormat(
                  value.total_movement,
                  false,
                  true
                )}
              />
            ) : (
              <DetailRow
                key={innerKey}
                name={innerKey}
                forecast={customCurrencyFormat(
                  value.earliest_balance,
                  false,
                  true
                )}
                actual={customCurrencyFormat(value.latest_balance, false, true)}
                ytdAverage={customCurrencyFormat(
                  value.total_movement,
                  false,
                  true
                )}
              />
            )
          )
        )}
    </Tbody>
  );
};

const DetailView = ({ customer_id, dashDate, trigger, triggerSetter }) => {
  const formattedDate = dashDate.toISOString().split("T")[0];

  const [ytdData, setYtdData] = useState({
    income: "0.00",
    cogs: "0.00",
    gross_profit: "0.00",
    marketing: "0.00",
    team: "0.00",
    practice_costs: "0.00",
    overhead: "0.00",
    business_development: "0.00",
    expense: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
  });

  const [actualData, setActualData] = useState({
    income: "0.00",
    cogs: "0.00",
    gross_profit: "0.00",
    marketing: "0.00",
    team: "0.00",
    practice_costs: "0.00",
    overhead: "0.00",
    business_development: "0.00",
    expense: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
  });

  const [forecastData, setForecastData] = useState({
    income: "0.00",
    cogs: "0.00",
    gross_profit: "0.00",
    marketing: "0.00",
    team: "0.00",
    practice_costs: "0.00",
    overhead: "0.00",
    business_development: "0.00",
    expense: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
  });

  const [cashData, setCashData] = useState({
    "Bank Accounts": {},
    "Credit Cards": {},
    "Total Movement": {},
  });

  const [balanceSheetData, setBalanceSheetData] = useState({
    acc_recievable: "0.00",
    last_acc_recievable: "0.00",
    bank_accounts: "0.00",
    last_bank_accounts: "0.00",
    acc_payable: "0.00",
    last_acc_payable: "0.00",
    credit_cards: "0.00",
    last_credit_cards: "0.00",
    fixed_assets: "0.00",
    last_fixed_assets: "0.00",
    current_assets: "0.00",
    last_current_assets: "0.00",
    long_term_liabilities: "0.00",
    last_long_term_liabilities: "0.00",
    current_liabilities: "0.00",
    last_current_liabilities: "0.00",
    equity: "0.00",
    last_equity: "0.00",

    acc_recievable_movement: "0.00",
    bank_accounts_movement: "0.00",
    acc_payable_movement: "0.00",
    credit_cards_movement: "0.00",
    fixed_assets_movement: "0.00",
    current_assets_movement: "0.00",
    long_term_liabilities_movement: "0.00",
    current_liabilities_movement: "0.00",
    equity_movement: "0.00",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expense, setExpense] = useState("");
  const [vendorExpense, setVendorExpense] = useState("");
  const [hasXOverflow, setHasXOverflow] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [optionalWidthX, setOptionalWidthX] = useState(null);
  const [hasCashXOverflow, setHasCashXOverflow] = useState(false);
  const [scrollCashWidth, setScrollCashWidth] = useState(0);
  const [optionalCashWidthX, setOptionalCashWidthX] = useState(null);
  const [hasXBalanceOverflow, setHasBalanceXOverflow] = useState(false);
  const [scrollBalanceWidth, setScrollBalanceWidth] = useState(0);
  const [optionalBalanceWidthX, setOptionalBalanceWidthX] = useState(null);
  const contentRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const contentCashRef = useRef(null);
  const scrollContainerCashRef = useRef(null);
  const contentBalanceRef = useRef(null);
  const scrollContainerBalanceRef = useRef(null);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshActualData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/actualvalues/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setActualData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshActualData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshYtdData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/ytdaverage/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setYtdData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshYtdData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshForecastData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/detailforecasts/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setForecastData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshForecastData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshCashData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/cashmovement/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setCashData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshCashData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshBalanceSheetData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/balancesheetoverview/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setBalanceSheetData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshBalanceSheetData(customer_id);

    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current && scrollContainerRef.current) {
        const hasOverflowX =
          contentRef.current.scrollWidth > contentRef.current.clientWidth;
        setHasXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentRef.current.scrollWidth;
          const visibleWidth = scrollContainerRef.current.clientWidth;
          setOptionalWidthX(visibleWidth);

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollWidth(thumbWidth);
        } else {
          setScrollWidth(0);
        }
      }
    };

    // MutationObserver to detect DOM changes
    const observer = new MutationObserver(() => {
      checkOverflow(); // Check for overflow when the DOM changes
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    window.addEventListener("resize", checkOverflow);

    checkOverflow();

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [formattedDate, setOptionalWidthX]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current && scrollContainerRef.current) {
        const hasOverflowX =
          contentRef.current.scrollWidth > contentRef.current.clientWidth;
        setHasXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentRef.current.scrollWidth;
          const visibleWidth = scrollContainerRef.current.clientWidth;
          setOptionalWidthX(visibleWidth);

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollWidth(thumbWidth);
        } else {
          setScrollWidth(0);
        }
      }
    };

    // MutationObserver to detect DOM changes
    const observer = new MutationObserver(() => {
      checkOverflow(); // Check for overflow when the DOM changes
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    window.addEventListener("resize", checkOverflow);

    checkOverflow();

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [formattedDate, setOptionalWidthX]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentCashRef.current && scrollContainerCashRef.current) {
        const hasOverflowX =
        contentCashRef.current.scrollWidth > contentCashRef.current.clientWidth;
        setHasCashXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentCashRef.current.scrollWidth;
          const visibleWidth = scrollContainerCashRef.current.clientWidth;
          setOptionalCashWidthX(visibleWidth);

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollCashWidth(thumbWidth);
        } else {
          setScrollCashWidth(0);
        }
      }
    };

    // MutationObserver to detect DOM changes
    const observer = new MutationObserver(() => {
      checkOverflow(); // Check for overflow when the DOM changes
    });

    if (contentCashRef.current) {
      observer.observe(contentCashRef.current, {
        childList: true,
        subtree: true,
      });
    }

    window.addEventListener("resize", checkOverflow);

    checkOverflow();

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [formattedDate, setOptionalCashWidthX]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentBalanceRef.current && scrollContainerBalanceRef.current) {
        const hasOverflowX =
        contentBalanceRef.current.scrollWidth > contentBalanceRef.current.clientWidth;
        setHasBalanceXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentBalanceRef.current.scrollWidth;
          const visibleWidth = scrollContainerBalanceRef.current.clientWidth;
          setOptionalBalanceWidthX(visibleWidth);

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollBalanceWidth(thumbWidth);
        } else {
          setScrollBalanceWidth(0);
        }
      }
    };

    // MutationObserver to detect DOM changes
    const observer = new MutationObserver(() => {
      checkOverflow(); // Check for overflow when the DOM changes
    });

    if (contentBalanceRef.current) {
      observer.observe(contentBalanceRef.current, {
        childList: true,
        subtree: true,
      });
    }

    window.addEventListener("resize", checkOverflow);

    checkOverflow();

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [formattedDate, setOptionalBalanceWidthX]);

  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  return (
    <>
      <VendorModal
        isOpen={isModalOpen}
        toggle={toggle}
        title={`${expense} Summary`}
        formattedDate={formattedDate}
        expenseType={vendorExpense}
        id={customer_id}
      />
      <Stack
        fontFamily={"lato"}
        width="100%"
        align="left"
        p="20px 30px"
        mt={isMobile ? "170px" : "125px"}
        overflowY={"auto"}
      >
        <Stack>
          <Text fontSize={"22px"} p={"0px 0px 0px 15px"} fontWeight={500}>
            Profit & Loss Breakdown
          </Text>
          <Card width="100%" height="100%" borderRadius="20px">
            <TableContainer
              ref={contentRef}
              borderRadius="20px"
              overflowX={"hidden"}
            >
              <Table variant="unstyled">
                <Thead
                  position="sticky"
                  top={-1}
                  zIndex={1}
                  borderRadius="20px"
                >
                  <Tr
                    padding={"10px 0px"}
                    backgroundColor={"#30384A"}
                    textColor={"white"}
                  >
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          {" "}
                          Category
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Forecast
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Actual
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          YTD Average
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  <DetailRow
                    name="Income"
                    forecast={customCurrencyFormat(
                      forecastData?.income_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.income ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.income ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Income"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"income"}
                  />
                  <DetailRow
                    name="Products (COGS)"
                    forecast={customCurrencyFormat(
                      forecastData?.cogs_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.cogs ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.cogs ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Cogs"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"cogs"}
                  />

                  <LowerDetailRow
                    name="Gross Profit"
                    forecast={customCurrencyFormat(
                      forecastData?.gross_profit_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.gross_profit ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.gross_profit ?? "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Marketing"
                    forecast={customCurrencyFormat(
                      forecastData?.marketing_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.marketing ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.marketing ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Marketing"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"marketing"}
                  />
                  <DetailRow
                    name="Team"
                    forecast={customCurrencyFormat(
                      forecastData?.team_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.team ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.team ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Team"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"team"}
                  />
                  <DetailRow
                    name="Practice Costs"
                    forecast={customCurrencyFormat(
                      forecastData?.practice_cost_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.practice_costs ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.practice_costs ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Practice Costs"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"practiceCosts"}
                  />
                  <DetailRow
                    name="Overhead"
                    forecast={customCurrencyFormat(
                      forecastData?.overhead_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.overhead ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.overhead ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Overheads"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"overheads"}
                  />
                  <DetailRow
                    name="Business Development"
                    forecast={customCurrencyFormat(
                      forecastData?.business_development_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.business_development ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.business_development ?? "0.00",
                      false,
                      true
                    )}
                    vendor={true}
                    isOpen={setIsModalOpen}
                    expenseSetter={setExpense}
                    expenseType="Business Development"
                    vendorExpenseSetter={setVendorExpense}
                    vendorExpense={"businessDevelopment"}
                  />
                  <LowerDetailRow
                    name="Total Expenses"
                    forecast={customCurrencyFormat(
                      forecastData?.expense_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.expense ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.expense ?? "0.00",
                      false,
                      true
                    )}
                  />
                  <LowerDetailRow
                    name="Net Operating Profit"
                    forecast={customCurrencyFormat(
                      forecastData?.net_operating_profit_forecast ?? "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.profit ?? "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.profit ?? "0.00",
                      false,
                      true
                    )}
                  />
                  <DetailRow
                    name="Net Profit Margin"
                    forecast={customCurrencyFormat(
                      forecastData?.net_profit_margin_forecast ?? 0.0,
                      true
                    )}
                    actual={customCurrencyFormat(
                      actualData?.profit_percentage ?? 0.0,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      ytdData?.profit_percentage ?? 0.0,
                      true
                    )}
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          <Box
            position={"relative"}
            width={"100%"}
            marginTop={"10px"}
            ref={scrollContainerRef}
          >
            {hasXOverflow ? (
              <HorizontalScrollbar
                targetRef={contentRef}
                optional_width={optionalWidthX}
                sx={{
                  position: "absolute",
                  right: 0, // Align to the right
                  top: 0,
                  width: "100%",
                  height: "100%",
                  visibility: hasXOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    width: `${scrollWidth}%`,
                    visibility: hasXOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasXOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Box>
          <Text fontSize={"22px"} fontWeight={500} p={"0px 0px 0px 15px"}>
            Cash Movement
          </Text>
          <Card width="100%" height="100%" borderRadius="20px">
            <TableContainer ref={contentCashRef} borderRadius="20px" overflowX={"hidden"}>
              <Table variant="unstyled">
                <Thead
                  position="sticky"
                  top={-1}
                  zIndex={1}
                  borderRadius="20px"
                >
                  <Tr
                    padding={"10px 0px"}
                    backgroundColor={"#30384A"}
                    textColor={"white"}
                  >
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Account
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Start of Month Balance
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          End of Month Balance
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Total Movement
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                  </Tr>
                </Thead>
                <PopulateCashTable data={cashData} />
              </Table>
            </TableContainer>
          </Card>
          <Box
            position={"relative"}
            width={"100%"}
            marginTop={"10px"}
            ref={scrollContainerCashRef}
          >
            {hasCashXOverflow ? (
              <HorizontalScrollbar
                targetRef={contentCashRef}
                optional_width={optionalCashWidthX}
                sx={{
                  position: "absolute",
                  right: 0, // Align to the right
                  top: 0,
                  width: "100%",
                  height: "100%",
                  visibility: hasCashXOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    width: `${scrollCashWidth}%`,
                    visibility: hasCashXOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasCashXOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Box>

          <Text fontSize={"22px"} fontWeight={500} p={"0px 0px 0px 15px"}>
            Balance Sheet Overview
          </Text>
          <Card width="100%" height="100%" borderRadius="20px">
            <TableContainer ref={contentBalanceRef} borderRadius="20px" overflowX={"hidden"}>
              <Table variant="unstyled">
                <Thead
                  position="sticky"
                  top={-1}
                  zIndex={1}
                  borderRadius="20px"
                >
                  <Tr
                    padding={"10px 0px"}
                    backgroundColor={"#30384A"}
                    textColor={"white"}
                  >
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          {" "}
                          Category
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Previous Month
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Current Month
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                    <Td fontSize={"18px"}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text textAlign={"left"} mr={4}>
                          Movement
                        </Text>
                        <DropDownIcon />
                      </Flex>
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {((balanceSheetData?.last_acc_recievable !== "0.00" &&
                    balanceSheetData?.last_acc_recievable != null) ||
                    (balanceSheetData?.acc_recievable !== "0.00" &&
                      balanceSheetData?.acc_recievable != null)) && (
                    <DetailRow
                      name="Accounts Recievable"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_acc_recievable
                          : 0,
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData ? balanceSheetData.acc_recievable : 0,
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.acc_recievable_movement
                          : 0,
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_bank_accounts !== "0.00" &&
                    balanceSheetData?.last_bank_accounts != null) ||
                    (balanceSheetData?.bank_accounts !== "0.00" &&
                      balanceSheetData?.bank_accounts != null)) && (
                    <DetailRow
                      name="Bank Accounts"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_bank_accounts
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.bank_accounts
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.bank_accounts_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_acc_payable !== "0.00" &&
                    balanceSheetData?.last_acc_payable != null) ||
                    (balanceSheetData?.acc_payable !== "0.00" &&
                      balanceSheetData?.acc_payable != null)) && (
                    <DetailRow
                      name="Accounts Payable"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_acc_payable
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.acc_payable
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.acc_payable_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_credit_cards !== "0.00" &&
                    balanceSheetData?.last_credit_cards != null) ||
                    (balanceSheetData?.credit_cards !== "0.00" &&
                      balanceSheetData?.credit_cards != null)) && (
                    <DetailRow
                      name="Credit Cards"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_credit_cards
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.credit_cards
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.credit_cards_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_fixed_assets !== "0.00" &&
                    balanceSheetData?.last_fixed_assets != null) ||
                    (balanceSheetData?.fixed_assets !== "0.00" &&
                      balanceSheetData?.fixed_assets != null)) && (
                    <DetailRow
                      name="Fixed Assets"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_fixed_assets
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.fixed_assets
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.fixed_assets_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_current_assets !== "0.00" &&
                    balanceSheetData?.last_current_assets != null) ||
                    (balanceSheetData?.current_assets !== "0.00" &&
                      balanceSheetData?.current_assets != null)) && (
                    <DetailRow
                      name="Current Assets"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_current_assets
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.current_assets
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.current_assets_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_long_term_liabilities !== "0.00" &&
                    balanceSheetData?.last_long_term_liabilities != null) ||
                    (balanceSheetData?.long_term_liabilities !== "0.00" &&
                      balanceSheetData?.long_term_liabilities != null)) && (
                    <DetailRow
                      name="Long-Term Liabilities"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_long_term_liabilities
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.long_term_liabilities
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.long_term_liabilities_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  {((balanceSheetData?.last_current_liabilities !== "0.00" &&
                    balanceSheetData?.last_current_liabilities != null) ||
                    (balanceSheetData?.current_liabilities !== "0.00" &&
                      balanceSheetData?.current_liabilities != null)) && (
                    <DetailRow
                      name="Current Liabilities"
                      forecast={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.last_current_liabilities
                          : "0.00",
                        false,
                        true
                      )}
                      actual={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.current_liabilities
                          : "0.00",
                        false,
                        true
                      )}
                      ytdAverage={customCurrencyFormat(
                        balanceSheetData
                          ? balanceSheetData.current_liabilities_movement
                          : "0.00",
                        false,
                        true
                      )}
                    />
                  )}
                  <LowerDetailRow
                    name="Equity"
                    forecast={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.last_equity : "0.00",
                      false,
                      true
                    )}
                    actual={customCurrencyFormat(
                      balanceSheetData ? balanceSheetData.equity : "0.00",
                      false,
                      true
                    )}
                    ytdAverage={customCurrencyFormat(
                      balanceSheetData
                        ? balanceSheetData.equity_movement
                        : "0.00",
                      false,
                      true
                    )}
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          <Box
            position={"relative"}
            width={"100%"}
            marginTop={"10px"}
            ref={scrollContainerBalanceRef}
          >
            {hasXBalanceOverflow ? (
              <HorizontalScrollbar
                targetRef={contentBalanceRef}
                optional_width={optionalBalanceWidthX}
                sx={{
                  position: "absolute",
                  right: 0, // Align to the right
                  top: 0,
                  width: "100%",
                  height: "100%",
                  visibility: hasXBalanceOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    width: `${scrollBalanceWidth}%`,
                    visibility: hasXBalanceOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasXBalanceOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default DetailView;

const VendorModal = ({
  isOpen,
  id,
  toggle,
  title,
  formattedDate,
  expenseType,
}) => {
  const contentRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [hasXOverflow, setHasXOverflow] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [optionalWidthX, setOptionalWidthX] = useState(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [vendorData, setVendorData] = useState({});


  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshVendorData = async (id) => {
      if (id) {
        if (expenseType) {
          try {
            const res = await axios.get(
              `/api/v2/customers/${id}/vendorbreakdown/${formattedDate}/${expenseType}`,
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
                signal: controller.signal,
              }
            );
            if (isActive) {
              setVendorData(res.data);
            }
          } catch (err) {
            if (err?.response) {
              console.log(err);
            }
          }
        } else {
          setVendorData({});
        }
      } else {
        setVendorData({});
      }
    };

    refreshVendorData(id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [id, formattedDate, expenseType]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasOverflowY =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setHasOverflow(hasOverflowY);

        if (hasOverflowY) {
          const contentHeight = contentRef.current.scrollHeight;
          const visibleHeight = contentRef.current.clientHeight;
          setScrollHeight((visibleHeight / contentHeight) * 100); // Set scrollbar thumb height as a percentage
        } else {
          setScrollHeight(0); // Reset thumb height if no overflow
        }
      }
    };

    checkOverflow();

    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    window.addEventListener("resize", checkOverflow);

    return () => {
      if (observer) observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [vendorData]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current && scrollContainerRef.current) {
        const hasOverflowX =
          contentRef.current.scrollWidth > contentRef.current.clientWidth;
        setHasXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentRef.current.scrollWidth;
          const visibleWidth = scrollContainerRef.current.clientWidth;
          setOptionalWidthX(visibleWidth);

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollWidth(thumbWidth);
        } else {
          setScrollWidth(0);
        }
      }
    };

    // MutationObserver to detect DOM changes
    const observer = new MutationObserver(() => {
      checkOverflow(); // Check for overflow when the DOM changes
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    window.addEventListener("resize", checkOverflow);

    checkOverflow();

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [formattedDate, setOptionalWidthX]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      position={"sticky"}
      scrollBehavior="inside"
      isCentered
      height="100vh"
    >
      <ModalOverlay overflowY={"hidden"} />
      <ModalContent
        borderRadius="12px"
        overflow="hidden"
        maxHeight={"90vh"}
        width="auto"
        maxWidth="unset"
      >
        <ModalHeader
          p={"20px 20px 10px 20px"}
          fontSize={"20px"}
          fontWeight={"500"}
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0px 20px 20px 20px">
          <Flex flexDirection={"column"} position="relative">
            <TableContainer
              ref={contentRef}
              height={"100%"}
              maxHeight="60vh"
              overflowY="hidden"
              borderRadius="12px"
              position="relative"
              marginRight={hasOverflow ? "20px" : 0}
              overflowX={"hidden"}
            >
              <Card borderRadius="12px">
                <Table
                  variant="unstyled"
                  bg="white"
                  padding={0}
                  rounded={"12px"}
                >
                  <Thead position="sticky" top={0} zIndex={3} bg="#30384A">
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text
                            fontWeight={"400"}
                            fontSize={"16px"}
                            textAlign={"left"}
                            mr={4}
                          >
                            Vendor
                          </Text>
                          <DropDownIcon />
                        </Flex>
                      </Td>

                      <Td>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text
                            fontWeight={"400"}
                            fontSize={"16px"}
                            textAlign={"left"}
                            mr={4}
                          >
                            Amount
                          </Text>
                          <DropDownIcon />
                        </Flex>
                      </Td>
                    </Tr>
                  </Thead>

                  <Tbody zIndex={1}>
                    {vendorData?.vendors?.length > 0 ? (
                      vendorData.vendors.map((vendor) => (
                        <Tr key={vendor.id} height={"52px"}>
                          <Td
                            border="2px solid"
                            borderColor="gray.200"
                            p={"16px 18px"}
                            minWidth={"200px"}
                          >
                            <HStack width="100%" spacing="2">
                              <Flex
                                align="center"
                                justify="center"
                                fontWeight="400"
                                fontSize="16px"
                                minWidth="0" // Allow the Flex to shrink as needed
                              >
                                <Image
                                  src={
                                    vendor.logo ||
                                    `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                                  }
                                  alt={vendor.name || "Placeholder"}
                                  boxSize="30px"
                                  objectFit="cover"
                                  borderRadius="10px"
                                  mr="2"
                                />
                                <Text
                                  whiteSpace="nowrap"
                                  overflow="visible"
                                  textOverflow="clip"
                                >
                                  {vendor.name}
                                </Text>
                              </Flex>
                            </HStack>
                          </Td>
                          <Td
                            p={"16px 18px"}
                            border="2px solid"
                            backgroundColor={"#F6F8FE"}
                            borderColor="gray.200"
                          >
                            {customCurrencyFormat(
                              vendor.amount,
                              false,
                              true,
                              true,
                              true
                            )}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr
                        border="2px solid"
                        borderColor="gray.200"
                        height={"20px"}
                      >
                        <Td colSpan={2} textAlign="center">
                          No vendor data.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>

                  <Tfoot position="sticky" bottom={0} zIndex={3} bg="#30384A">
                    <Tr
                      fontSize="18px"
                      sx={{
                        "& td": {
                          color: "white",
                          fontWeight: "200",
                        },
                      }}
                    >
                      <Td>Total</Td>
                      <Td>
                        {customCurrencyFormat(
                          vendorData?.total ? vendorData.total : "0",
                          false,
                          true,
                          true,
                          true
                        )}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </Card>
            </TableContainer>

            {hasOverflow ? (
              <Scrollbar
                targetRef={contentRef}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  height: "100%",
                  visibility: hasOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    height: `${scrollHeight}%`,
                    visibility: hasOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Flex>
          <Box
            position={"relative"}
            width={"100%"}
            marginTop={"10px"}
            ref={scrollContainerRef}
          >
            {hasXOverflow ? (
              <HorizontalScrollbar
                targetRef={contentRef}
                optional_width={optionalWidthX}
                sx={{
                  position: "absolute",
                  right: 0, // Align to the right
                  top: 0,
                  width: "100%",
                  height: "100%",
                  visibility: hasXOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    width: `${scrollWidth}%`,
                    visibility: hasXOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasXOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
