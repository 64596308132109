import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { FaLock, FaUserAlt } from "react-icons/fa";
import PasswordChecklist from "react-password-checklist";
import { useSearchParams } from "react-router-dom";
import { Context } from "../ContextWrapper";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const ResetPassword = ({ register }) => {
  const [searchParams] = useSearchParams();
  const context = React.useContext(Context);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPassValid, setIsPassValid] = useState(false);

  const [password1, setPassword1] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);

  const handleShowClick1 = () => setShowPassword1(!showPassword1);
  const handleShowClick2 = () => setShowPassword2(!showPassword2);

  const s = searchParams.get("s");

  const submit = async (e) => {
    e.preventDefault();
    const data = {
      s: s,
      email: email,
      password: password1,
    };
    await axios
      .post(
        "/api/v2/resetpassword/",
        data,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setDisabled(true);
        if (res.data.reset) {
          var header = "Reset password";
          var msg = "Password reset successfully";
          if (register) {
            header = "Create password";
            msg = "Password created, please go to the login page";
          }
          context.fireToast(header, msg, "success");
        } else {
          if (register) {
            context.fireToast(
              "Create password",
              "Password not create.",
              "error"
            );
          } else {
            context.fireToast("Reset password", "Password not reset.", "error");
          }
        }
      });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
      minHeight={"750px"}
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={submit}>
            <Stack
              spacing={4}
              p="1.75rem"
              width={"90vw"}
              maxW={"438px"}
              backgroundColor="#FFFFFF"
              boxShadow="0px 8px 16px 0px #00000026"
              borderRadius={"5px"}
              alignItems={"center"}
            >
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Text fontSize={"24px"} fontWeight={400}>
                  Welcome to
                </Text>
                <Image
                  width={"221px"}
                  src="lucro-logo-light.png"
                  alt="Dan Abramov"
                />
              </Box>
              <Text fontWeight={500} fontSize={"16px"} textAlign={"center"}>
                Enter your email and your new password.
              </Text>
              <FormControl w={"80vw"} maxW={"374px"}>
                <Text fontWeight={500} fontSize={"16px"} mb={2}>
                  Email
                </Text>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="#0A101B" />}
                  />
                  <Input
                    type="text"
                    placeholder="Enter your email address"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl w={"80vw"} maxW={"374px"}>
                <Text fontWeight={500} fontSize={"16px"} mb={2}>
                  New password
                </Text>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="#0A101B" />}
                  />
                  <Input
                    type={showPassword1 ? "text" : "password"}
                    placeholder="Enter your new password"
                    value={password1}
                    required
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                  <InputRightElement width="50px">
                    <Button
                      h="15px"
                      w={"20px"}
                      backgroundColor={"#FFFFFF"}
                      _hover={{ bg: "#FFFFFF" }}
                      color={"#0A101B"}
                      p={0}
                      onClick={handleShowClick1}
                    >
                      {showPassword1 ? <EyeOff></EyeOff> : <Eye></Eye>}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl w={"80vw"} maxW={"374px"}>
                <Text fontWeight={500} fontSize={"16px"} mb={2}>
                  Confirm password
                </Text>
                <InputGroup mb={4}>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="#0A101B" />}
                  />
                  <Input
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Confirm new password"
                    value={password2}
                    required
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  <InputRightElement width="50px">
                    <Button
                      h="15px"
                      w={"20px"}
                      backgroundColor={"#FFFFFF"}
                      _hover={{ bg: "#FFFFFF" }}
                      color={"#0A101B"}
                      p={0}
                      onClick={handleShowClick2}
                    >
                      {showPassword2 ? <EyeOff></EyeOff> : <Eye></Eye>}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={5}
                  value={password1}
                  valueAgain={password2}
                  onChange={(isValid) => {
                    setIsPassValid(isValid);
                  }}
                />
              </FormControl>
              <Button
                mt={2}
                borderRadius={"4px"}
                type="submit"
                variant="solid"
                _hover={{ bg: "#535DEC" }}
                backgroundColor={"#536DEC"}
                w={"80vw"}
                maxW={"374px"}
                isDisabled={disabled || !isPassValid}
              >
                Submit
              </Button>
              <FormControl w={"80vw"} maxW={"374px"}>
                <FormHelperText
                  textAlign="center"
                  fontSize={"16px"}
                  fontWeight={400}
                  color={"#234E94"}
                >
                  <Link href="/login">Go to login</Link>
                </FormHelperText>
              </FormControl>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
