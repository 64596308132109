import React, { useState, useEffect } from "react"
import axios from "axios";
import { useParams, Link, useLocation } from 'react-router-dom';
import { ClientBankTransactions } from "./clientBankTransactions";
import { ClientPlaid } from "./clientPlaid";
import { ClientQuickbooks } from "./clientQuickbooks";
import { ClientDropDown } from "./clientDropDown";
import { ChartOfAccounts } from "../account/chartOfAccounts"
import { Button, Box, Tabs, TabList, TabPanels, TabPanel, Text, useTab, Spacer, Divider, HStack } from '@chakra-ui/react';
import { BarChart, CreditCard, Briefcase, Book, Cpu, CornerDownLeft, BookOpen, Table, Columns, CheckSquare } from "react-feather";
import { ClientJournals } from "./clientJournals";
import { ClientGeneralLedger } from "./clientGeneralLedger";
import { ClientReconciliations } from "./clientReconciliations";
import { GrowthPlan } from "../dashboard/growthPlan";
import { YearlyBudget } from "../dashboard/yearlybudget";
import { useCurrentProfile } from "../../ContextWrapper";
import { ClientBankRules } from "./clientBankRules";
import { AccessToken } from "../../utils";


export const ClientDetail = () => {
  const [customer, setCustomer] = useState({});
  const {customer_id} = useParams()
  const { profile } = useCurrentProfile();
  const { hash } = useLocation();

  // TODO: this is horrible but the Quickbooks tab is hopefully temporary
  const activeTab = hash === "#qb" ? 7 : 0;

  useEffect(() => {
    let accessToken = AccessToken()
    if (accessToken === null) {
      window.location.href = '/login'
    }
    else {
      axios
      .get(`/api/v2/customers/${customer_id}/`,
          {headers: {'Content-Type': 'application/json'}},
          {withCredentials: true})
      .then((res) => setCustomer(res.data))
      .catch((err) => console.log(err));
    }
  }, [customer_id]);

  return (
      <Tabs orientation="vertical" isLazy defaultIndex={activeTab}>
        <TabList border="0" bg="brand.1000" color="white">
          <Divider m="0" color="gray.50" />
          {customer ? <ClientDropDown customer_id={customer.id} /> : null}
          <Box pl="6" pr="6">
            <Divider color="gray.50" />
          </Box>
          <Text fontSize='sm' color="gray.300" pl="8" pt="6" mb="0">Your Company</Text>
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><CreditCard/></Box>Transactions</CustomTab>}
          <CustomTab justifyContent="stretch"><Box mr="2"><Briefcase/></Box>Plaid</CustomTab>
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><Cpu/></Box>Chart Of Accounts</CustomTab>}
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><CheckSquare/></Box>Bank Rules</CustomTab>}
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><BookOpen/></Box>Journals</CustomTab>}
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><Table/></Box>General Ledger</CustomTab>}
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><Columns/></Box>Reconciliation</CustomTab>}
          <CustomTab justifyContent="stretch"><Box mr="2"><Book/></Box>Quickbooks Accounts</CustomTab>
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><BarChart/></Box>Dashboard</CustomTab>}
          {profile?.hasAccountingAccess && <CustomTab justifyContent="stretch"><Box mr="2"><BarChart/></Box>Yearly Budget</CustomTab>}
          <Spacer />

          <Box pl="6" pb="6" pr="6">
            <Divider color="gray.50" />
            <Text fontSize='sm' color="gray.300">Actions</Text>
            <Link to='/'>
              <HStack>
                <CornerDownLeft/>
                <Box>Back to client listing</Box>
              </HStack>
            </Link>
          </Box>
        </TabList>
        <TabPanels>
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <ClientBankTransactions customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
          <TabPanel p="0">
            <Box h='calc(100vh - 80px)'>
              {customer && <ClientPlaid customer_id={customer.id}/>}
            </Box>
          </TabPanel>
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <ChartOfAccounts customer_id={customer.id} template={false}/>}
              </Box>
            </TabPanel>
          }
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <ClientBankRules customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <ClientJournals customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <ClientGeneralLedger customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <ClientReconciliations customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
          <TabPanel p="0">
            <Box h='calc(100vh - 80px)'>
              {customer && <ClientQuickbooks customer_id={customer.id}/>}
            </Box>
          </TabPanel>
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <GrowthPlan customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
          {profile?.hasAccountingAccess &&
            <TabPanel p="0">
              <Box h='calc(100vh - 80px)'>
                {customer && <YearlyBudget customer_id={customer.id}/>}
              </Box>
            </TabPanel>
          }
        </TabPanels>
      </Tabs>
  )
}

const CustomTab = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref })
  const isSelected = !!tabProps['aria-selected']

  return (
    <Button w="290px" pt="6" pb="6" ml="4" mr="4" mt="2" mb="2" bg={isSelected ? "brand.600" : "brand.1000"} {...tabProps}>
      {tabProps.children}
    </Button>
  )
})
