import { Image, Skeleton, Stack } from "@chakra-ui/react";

export const renderAmount = (amount) => {
  if (!amount) {
    amount = 0;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const renderAmountWithType = (amount, type) => {
  if (!amount) {
    amount = 0;
  }

  amount = Math.abs(amount);
  if (type === "debit") {
    amount = amount * -1;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const oppositeType = (type) => {
  if (type === "debit") {
    return "credit";
  } else {
    return "debit";
  }
};

export const isTransactionLinked = (transaction) => {
  if (transaction.journal_entries.length === 0) {
    return false;
  }
  let entriesLinked = transaction.journal_entries.every((e) => e.account);
  return entriesLinked && transaction.vendor;
};

export const isTransactionAdded = (transaction) => {
  if (isTransactionLinked(transaction)) {
    return transaction.journal_entries.every((e) => e.added);
  }
  return false;
};

export const titleCase = (string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const LoadingTable = () => {
  return (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );
};

export const customCurrencyFormat = (
  numStr,
  percentage = false,
  wrapNegative = false,
  includeSymbol = true,
  includeZeroDecimal = false
) => {
  let num = parseFloat(numStr);
  if (isNaN(num)) return numStr;

  const isNegative = num < 0;

  let absNum = Math.abs(num).toFixed(2);

  let [integerPart, decimalPart] = absNum.split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedNumber =
    decimalPart !== "00"
      ? `${integerPart}.${decimalPart}`
      : includeZeroDecimal
      ? `${integerPart}.${decimalPart}`
      : integerPart;

  // Format the number based on percentage and wrapNegative options
  if (percentage) {
    return isNegative && wrapNegative
      ? `(${formattedNumber}${includeSymbol ? "%" : ""})`
      : isNegative
      ? `-${formattedNumber}${includeSymbol ? "%" : ""}`
      : `${formattedNumber}${includeSymbol ? "%" : ""}`;
  } else {
    return isNegative && wrapNegative
      ? `(${includeSymbol ? "$" : ""}${formattedNumber})`
      : isNegative
      ? `-${includeSymbol ? "$" : ""}${formattedNumber}`
      : `${includeSymbol ? "$" : ""}${formattedNumber}`;
  }
};

export const colorFormat = (numStr, oposite = false) => {
  let num = parseFloat(numStr);
  if (isNaN(num)) return "black";
  if (num === 0) return "black";

  const isNegative = num < 0;

  if (oposite) {
    if (isNegative) {
      return "#68BA65";
    } else {
      return "#FF5E5E";
    }
  } else {
    if (isNegative) {
      return "#FF5E5E";
    } else {
      return "#68BA65";
    }
  }
};

export const colorExpenseFormat = (
  pl,
  percentage,
  oposite = false,
  profit = false
) => {
  let num = parseFloat(pl);
  let percent = parseFloat(percentage);

  if (isNaN(num) || isNaN(percent)) {
    return profit ? "#536DEC" : "black";
  }

  let percentageOfPl = percent;

  let percentagePositive = oposite ? num + num * 0.1 : num - num * 0.08;
  let percentageWarning = oposite ? num - num * 0.2 : num + num * 0.12;
  let percentageCaution = oposite ? num - num * 0.35 : num + num * 0.25;

  if (oposite) {
    if (percentageOfPl >= parseFloat(percentagePositive)) {
      return "#68BA65";
    } else if (
      percentageOfPl < parseFloat(percentagePositive) &&
      percentageOfPl > parseFloat(percentageWarning)
    ) {
      return "#458CFF";
    } else if (
      percentageOfPl <= parseFloat(percentageWarning) &&
      percentageOfPl > parseFloat(percentageCaution)
    ) {
      return "#F1B20E";
    } else {
      return "#FF5E5E";
    }
  } else {
    if (percentageOfPl >= parseFloat(percentageCaution)) {
      return "#FF5E5E";
    } else if (
      percentageOfPl >= parseFloat(percentageWarning) &&
      percentageOfPl < parseFloat(percentageCaution)
    ) {
      return "#F1B20E";
    } else if (
      percentageOfPl > parseFloat(percentagePositive) &&
      percentageOfPl < parseFloat(percentageWarning)
    ) {
      return "#458CFF";
    } else {
      return "#68BA65";
    }
  }
};

export const getBackgroundColor = (imageType) => {
  switch (imageType) {
    case "/money-up-arrow.png":
      return "rgba(130, 128, 255, 0.18)";
    case "/money-down-arrow.png":
      return "rgba(255, 94, 94, 0.18)";
    case "/money-bag.png":
      return "rgba(104, 186, 101, 0.18)";
    case "/bar-chart-with-arrow.png":
      return "rgba(104, 186, 101, 0.18)";
    default:
      return "transparent";
  }
};

export const DropDownIcon = () => {
  return (
    <Image
      src={"/arrow-dropdown-icon.png"}
      alt="Drop down icon"
      boxSize="10px"
      objectFit="contain"
    />
  );
};

export const AccessToken = () => {
  const localToken = localStorage.getItem("access_token");
  const sessionToken = sessionStorage.getItem("access_token");

  if (localToken === null) {
    return sessionToken;
  } else {
    return localToken;
  }
};
