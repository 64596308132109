import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spacer,
  Stack,
  NumberInput,
  NumberInputField,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import CustomTableContainer from "../../theme/components/tableContainer";
import axios from "axios";
import { Context } from "../../ContextWrapper";
import { DropDown } from "../generic/dropDown";
import { Trash2 } from "react-feather";
import { useCurrentProfile } from "../../ContextWrapper";
import { VendorSelect } from "../account/vendorDropDown";
import { fetchCustomerAccounts } from "../../api/accounts";

export const ClientJournals = ({ customer_id }) => {
  const [journalList, setJournalList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [journal, setJournal] = useState(null);
  const {
    isOpen: isJournalOpen,
    onOpen: onJournalOpen,
    onClose: onJournalClose,
  } = useDisclosure();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const context = React.useContext(Context);
  const [error, setError] = useState([]);
  const { profile } = useCurrentProfile();

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("file_uploaded", file);

    setLoading(true);
    axios
      .post(
        `/api/v2/customers/${customer_id}/journal_entry_import/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          onUploadClose();
          refreshJournalList(customer_id);
          context.fireToast(
            "Success",
            "Successfully imported the GL Report",
            "success"
          );
        } else {
          onUploadClose();
          setLoading(false);
          setError(response.response.data.errors);
          onErrorOpen();
        }
      })
      .catch((error) => {
        onUploadClose();
        setLoading(false);
        setError(error.response.data.errors);
        onErrorOpen();
        console.error("There was an error uploading the file!", error);
      });
  };

  const refreshJournalList = (customer_id) => {
    if (customer_id) {
      axios
        .get(
          `/api/v2/customers/${customer_id}/journals/`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setJournalList(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    refreshJournalList(customer_id);
  }, [customer_id]);

  // const downloadTemplate = () => {
  //   setLoading(true);
  //   axios
  //     .post(
  //       `/api/v2/customers/${customer_id}/glexport/`,
  //       {},
  //       { headers: { "Content-Type": "application/json" } },
  //       { withCredentials: true }
  //     )
  //     .then((response) => {
  //       const link = document.createElement("a");
  //       link.href = response.data.file;
  //       link.setAttribute("download", response.data.name);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       setLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const createJournal = () => {
    setJournal({
      date: new Date().toISOString().split("T")[0],
      journal_entries: [],
    });
    onJournalOpen();
  };

  const editJournal = (journal) => {
    setJournal(journal);
    onJournalOpen();
  };

  const viewJournal = (journal) => {
    journal.viewOnly = true;
    setJournal(journal);
    onJournalOpen();
  };

  const handleSubmit = (data, vendor, submit = false) => {
    data.journal_entries = data.journal_entries.filter(
      (e) => e.entry_type !== ""
    );

    if (submit) {
      data.journal_entries = data.journal_entries.map((e) => {
        e.added = true;
        return e;
      });
      data.added = true;
    }

    if (vendor) {
      data.journal_entries = data.journal_entries.map((e) => {
        e.vendor = vendor;
        return e;
      });
    }

    if (data.id) {
      axios
        .patch(
          `/api/v2/customers/${customer_id}/journals/${data.id}/`,
          data,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setJournalList(
            journalList.map((o) => (o.id === res.data.id ? res.data : o))
          );
          onJournalClose();
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(
          `/api/v2/customers/${customer_id}/journals/`,
          data,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setJournalList([...journalList, res.data]);
          onJournalClose();
        })
        .catch((err) => console.log(err));
    }
  };

  const isJournalEditable = (journal) => {
    if (journal.journal_type !== "manual") {
      return false;
    }
    if (journal.added) {
      return false;
    }
    return true;
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="100%">
          {!isJournalOpen && (
            <>
              <UploadModal
                isOpen={isUploadOpen}
                onClose={onUploadClose}
                loading={loading}
                handleUpload={handleUpload}
              />
              <ErrorModal
                isOpen={isErrorOpen}
                onClose={onErrorClose}
                errors={error}
              />
              <DeleteAccountingDataModal
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                customer_id={customer_id}
                refreshJournalList={refreshJournalList}
              />
              <Flex pb="5">
                <Box>
                  <Heading as="h3" size="lg">
                    Journals
                  </Heading>
                </Box>
                <Spacer />
                <Box>
                  <Stack direction="row" spacing={6} align="center">
                    {profile?.isAdmin && (
                      <Button colorScheme="red" onClick={onDeleteOpen}>
                        Delete accounting data
                      </Button>
                    )}
                    {/* <Button onClick={downloadTemplate}>GL Template</Button> */}
                    <Button onClick={onUploadOpen}>Import GL</Button>
                    <Button onClick={createJournal}>Create Journal</Button>
                  </Stack>
                </Box>
              </Flex>
              <Box maxHeight="calc(100vh - 350px)" bg="white" overflowX="auto">
                <Table variant="unstyled">
                  <Thead position="sticky" top={-1} zIndex={1}>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Number</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {journalList?.map((journal) => (
                      <Tr key={journal.id}>
                        <Td>{journal.date}</Td>
                        <Td>{journal.number}</Td>
                        <Td>
                          {isJournalEditable(journal) && (
                            <Button onClick={() => editJournal(journal)}>
                              Edit
                            </Button>
                          )}
                          {!isJournalEditable(journal) && (
                            <Button onClick={() => viewJournal(journal)}>
                              View
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}
          {isJournalOpen && (
            <Journal
              customer_id={customer_id}
              journal={journal}
              handleSubmit={handleSubmit}
              onClose={onJournalClose}
            />
          )}
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const Journal = ({ customer_id, journal, handleSubmit, onClose }) => {
  const confirmText = !journal?.id ? "Create" : "Update";
  const [journalData, setJournalData] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [index, setIndex] = useState(1);
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState([]);

  const refreshJournalEntryList = useCallback(
    (customer_id, cursor = null) => {
      if (journal?.id) {
        let cursorParam = null;
        if (cursor) {
          const urlObj = new URL(cursor);
          cursorParam = urlObj.searchParams.get("cursor");
        }

        axios
          .get(
            `/api/v2/customers/${customer_id}/journalentries/?journal=${journal.id}`,
            {
              headers: { "Content-Type": "application/json" },
              params: { cursor: cursorParam },
              withCredentials: true,
            }
          )
          .then((res) => {
            journal.journal_entries = res.data.results;

            if (journal.journal_entries.length === 0) {
            } else {
              journal.journal_entries = journal.journal_entries.map((e, i) => {
                setVendor(e.vendor);
                e.index = i;
                return e;
              });
            }
            setIndex(journal.journal_entries.length + 1);
            setJournalData(journal);

            setNextCursor(res.data.next);
            setPreviousCursor(res.data.previous);
          })
          .catch((err) => console.log(err));
      } else {
        journal.journal_entries = [
          {
            index: 1,
            entry_type: "",
            customer: customer_id,
          },
          {
            index: 2,
            entry_type: "",
            customer: customer_id,
          },
        ];
        setIndex(journal.journal_entries.length + 1);
        setJournalData(journal);
        setNextCursor(null);
        setPreviousCursor(null);
      }
    },
    [journal]
  );

  useEffect(() => {
    refreshJournalEntryList(customer_id);
  }, [journal, customer_id, refreshJournalEntryList]);

  useEffect(() => {
    if (customer_id) {
      const getData = async () => {
        var accountResults = fetchCustomerAccounts(customer_id, {}, true);
        setAccountList(await accountResults);
      };
      getData();
    }
  }, [customer_id]);

  const handleNextPage = () => {
    if (nextCursor) {
      refreshJournalEntryList(customer_id, nextCursor);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      refreshJournalEntryList(customer_id, previousCursor);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJournalData({ ...journalData, [name]: value });
  };

  const updateVendor = (vendor) => {
    setVendor(vendor.value);
  };

  const addRow = () => {
    const name = "journal_entries";
    var entries = journalData.journal_entries;
    entries.push({
      index: index,
      entry_type: "",
      customer: customer_id,
    });
    setIndex((prev) => prev + 1);
    setJournalData({ ...journalData, [name]: entries });
  };

  const removeLine = (entry) => {
    const name = "journal_entries";
    var entries = [];
    if (entry.id) {
      entries = journalData.journal_entries.map((e) => {
        if (e.index === entry.index) {
          e.deleted = true;
        }
        return e;
      });
    } else {
      entries = journalData.journal_entries.filter(
        (e) => e.index !== entry.index
      );
    }
    setJournalData({ ...journalData, [name]: entries });
  };

  const updateEntryAccount = (account, { entry }) => {
    const name = "journal_entries";
    const entries = journalData.journal_entries.map((e) => {
      if (e.index === entry.index) {
        e.account = account.value;
      }
      return e;
    });
    setJournalData({ ...journalData, [name]: entries });
  };

  const changeEntryAmount = (entry, event, type) => {
    const name = "journal_entries";
    const entries = journalData.journal_entries.map((e) => {
      if (e.index === entry.index) {
        e.amount = Number(event.target.value).toFixed(2);
        if (e.amount > 0) {
          e.entry_type = type;
        } else {
          e.entry_type = "";
        }
      }
      return e;
    });
    setJournalData({ ...journalData, [name]: entries });
  };

  const changeEntryDescription = (entry, event) => {
    const name = "journal_entries";
    const entries = journalData.journal_entries.map((e) => {
      if (e.index === entry.index) {
        e.description = event.target.value;
      }
      return e;
    });
    setJournalData({ ...journalData, [name]: entries });
  };

  const getTotal = (type) => {
    return journalData?.journal_entries
      ?.filter((e) => e.entry_type === type && !e.deleted)
      .reduce((sum, entry) => sum + parseFloat(entry.amount || 0), 0)
      .toFixed(2);
  };

  const isJournalValid = () => {
    const debits = getTotal("debit");
    const credits = getTotal("credit");

    const isMatched = debits === credits && debits > 0;
    const allAccountsSelected = journalData?.journal_entries?.every(
      (entry) => entry.account
    );

    return isMatched && allAccountsSelected && vendor;
  };
  const isSubmitDisabled = !isJournalValid();

  return (
    <>
      <Flex pb="5">
        <Button onClick={onClose}>Back</Button>
        <Spacer />
        <Heading as="h3" size="lg">
          {confirmText} Journal {journalData.number}
        </Heading>
        <Spacer />
        {!journal.viewOnly && (
          <>
            <Button
              mr={3}
              onClick={() => handleSubmit(journalData, vendor, false)}
            >
              Save
            </Button>
            <Button
              isDisabled={isSubmitDisabled}
              onClick={() => handleSubmit(journalData, vendor, true)}
            >
              Submit
            </Button>
          </>
        )}
      </Flex>
      <HStack pb={5}>
        <FormControl w="250px">
          <FormLabel>Journal Date</FormLabel>
          <Input
            type="date"
            name="date"
            onChange={handleInputChange}
            defaultValue={journalData.date}
            isReadOnly={journal.viewOnly}
          />
        </FormControl>
        {journal.journal_type !== "import" && (
          <>
            <Spacer />
            <FormControl>
              <FormLabel>Vendor</FormLabel>
              <VendorSelect selectedVendorID={vendor} onChange={updateVendor} />
            </FormControl>
          </>
        )}
      </HStack>
      <Box height="calc(100vh - 350px)" bg="white" overflowX="auto">
        <Table variant="unstyled">
          <Thead>
            <Tr>
              <Th w="400px">Account</Th>
              <Th w="250px">Debit</Th>
              <Th w="250px">Credit</Th>
              <Th>Description</Th>
              {!journal.viewOnly && <Th style={{ width: "118px" }}>Action</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {journalData?.journal_entries
              ?.filter((e) => !e.deleted)
              .map((entry) => (
                <Tr key={entry.index}>
                  <Td>
                    <DropDown
                      name="account"
                      options={accountList}
                      selectedValue={entry.account}
                      metaData={{ entry }}
                      onChange={updateEntryAccount}
                      isReadOnly={journal.viewOnly}
                    />
                  </Td>
                  <Td>
                    {["", "debit"].indexOf(entry.entry_type) > -1 && (
                      <NumberInput
                        defaultValue={entry.amount}
                        isReadOnly={journal.viewOnly}
                      >
                        <NumberInputField
                          onChange={(e) => changeEntryAmount(entry, e, "debit")}
                        />
                      </NumberInput>
                    )}
                  </Td>
                  <Td>
                    {["", "credit"].indexOf(entry.entry_type) > -1 && (
                      <NumberInput
                        defaultValue={entry.amount}
                        isReadOnly={journal.viewOnly}
                      >
                        <NumberInputField
                          onChange={(e) =>
                            changeEntryAmount(entry, e, "credit")
                          }
                        />
                      </NumberInput>
                    )}
                  </Td>
                  <Td>
                    <Input
                      type="text"
                      value={entry.description}
                      onChange={(e) => changeEntryDescription(entry, e)}
                      isReadOnly={journal.viewOnly}
                    />
                  </Td>
                  {!journal.viewOnly && (
                    <Td>
                      <IconButton
                        variant="outline"
                        colorScheme="black"
                        aria-label="Delete line"
                        icon={<Trash2 />}
                        onClick={() => removeLine(entry)}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Stack direction="row" spacing={4} mt={4} justifyContent="center">
          {previousCursor && (
            <Button onClick={handlePreviousPage}>Previous</Button>
          )}
          {nextCursor && (
            <Button onClick={handleNextPage} disabled={!nextCursor}>
              Next
            </Button>
          )}
        </Stack>
      </Box>

      {!journal.viewOnly && <Button onClick={addRow}>+</Button>}
    </>
  );
};

const UploadModal = ({ isOpen, onClose, loading, handleUpload }) => {
  const [file, setFile] = useState(null);

  const onUploadFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload GL</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Files</FormLabel>
            <Input
              type="file"
              accept=".xlsx"
              sx={{
                "::file-selector-button": {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: "none",
                  border: "none",
                  fontWeight: "bold",
                },
              }}
              onChange={onUploadFile}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => handleUpload(file)} isDisabled={loading}>
            {loading ? "Processing..." : "Upload"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Errors</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errors &&
            errors.map((e, i) => (
              <>
                <Text key={i} as="b">
                  {e.error}
                </Text>
                {e.detail.map((d, i2) => (
                  <Text key={i + i2} mb={0}>
                    {d}
                  </Text>
                ))}
              </>
            ))}
          {!errors && <Text>Server error, please check template format</Text>}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteAccountingDataModal = ({
  isOpen,
  onClose,
  customer_id,
  refreshJournalList,
}) => {
  const deleteAccountingData = () => {
    axios
      .post(
        `/api/v2/customers/${customer_id}/deleteaccounting/`,
        {},
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((response) => {
        refreshJournalList(customer_id);
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Accounting data.</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          You are about to PERMANENTLY delete: <br />
          Journal entries <br />
          Journals <br />
          Account balances <br />
          Transaction vendor links <br />
          Bank account review counts <br />
          <br />
          This can't be undone.
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={deleteAccountingData}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
