import React, { useState, useEffect, useCallback } from "react";
import { Select } from "chakra-react-select";
import { HStack, Box, Button } from "@chakra-ui/react";
import { VendorsQuickCreate } from "../vendors/vendorQuickCreate";
import axios from "axios";

export const VendorSelect = ({ selectedVendorID, onChange, metaData, w, menuPortalTarget }) => {
  const [selected, setSelected] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [isQuickCreateOpen, setIsQuickCreateOpen] = useState(false);
  const [vendors, setVendors] = useState([])

  const refreshVendorsList = useCallback(() => {
    let params = {};
    if (searchInput) {
      params.name__icontains = searchInput;
    } else if (selectedVendorID) {
      params.id = selectedVendorID;
    }

    axios
      .get("/api/v2/vendors/", {
        headers: { "Content-Type": "application/json" },
        params: params,
        withCredentials: true,
      })
      .then((res) => {
        setVendors(res.data.results.map((v) => ({ "value": v.id, "label": v.name })));
      })
      .catch((err) => console.log(err));
  }, [searchInput, selectedVendorID]);

  useEffect(() => {
    refreshVendorsList();
  }, [refreshVendorsList]);

  useEffect(() => {
    setSelected(vendors.find((a) => a.value === selectedVendorID));
  }, [vendors, selectedVendorID]);

  const onChangeLocal = (vendor) => {
    setSelected(vendor);
    onChange(vendor, metaData);
  };

  const onInputChange = (inputValue) => {
    let result = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setSearchInput(result);
  };

  const addVendorToList = (newVendor) => {
    vendors.push(newVendor);
    setSelected(newVendor);
    onChange(newVendor, metaData);
  };

  const handleOpenQuickCreate = () => {
    setIsQuickCreateOpen(true);
  };

  const handleCloseQuickCreate = () => {
    setIsQuickCreateOpen(false);
  };

  return (
    <HStack spacing={2}>
      <Box width={w ? w : "200px"}>
        <Select
          menuPortalTarget={menuPortalTarget}
          name="vendor"
          options={vendors}
          value={selected}
          placeholder="Select vendor..."
          onChange={onChangeLocal}
          onInputChange={onInputChange}
          useBasicStyles
          flex="1"
          noOptionsMessage={() =>
            <Button onClick={handleOpenQuickCreate}>Add {searchInput}</Button>
          }
        />
      </Box>
      {isQuickCreateOpen && (
        <VendorsQuickCreate
          addVendorToList={addVendorToList}
          isOpen={isQuickCreateOpen}
          onClose={handleCloseQuickCreate}
          initialName={searchInput}
        />
      )}
    </HStack>
  );
};
