import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Container,
  Box,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  Spinner,
  Textarea,
  Heading,
  Flex,
  Stack,
  Spacer,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import CustomTableContainer from "../../theme/components/tableContainer";
import { AddIcon } from "@chakra-ui/icons";
import { renderAmount } from "../../utils";
import { Context } from "../../ContextWrapper";
import { DropDown } from "../generic/dropDown";
import { Upload } from "react-feather";
import {
  fetchAccounts,
  fetchAccountSubTypes,
  fetchAccountTaxTypes,
  fetchAccountTypes,
  fetchCustomerAccounts,
} from "../../api/accounts";

export const ChartOfAccounts = ({ customer_id, template = false }) => {
  const [loading, setLoading] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [accountSubTypeList, setAccountSubTypeList] = useState([]);
  const [accountTaxTypeList, setAccountTaxTypeList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({});
  const context = React.useContext(Context);
  const [error, setError] = useState([]);
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  useEffect(() => {
    const getData = async () => {
      const typeResults = fetchAccountTypes();
      const subTypeResults = fetchAccountSubTypes();
      const taxTypeResults = fetchAccountTaxTypes();

      var accountResults;
      if (template) {
        accountResults = fetchAccounts();
      } else {
        accountResults = fetchCustomerAccounts(customer_id);
      }

      setAccountTypeList(await typeResults);
      setAccountSubTypeList(await subTypeResults);
      setAccountTaxTypeList(await taxTypeResults);
      setAccountList(await accountResults);
    };
    getData();
    setLoading(false);
  }, [customer_id, template]);

  const toggle = () => {
    setCurrentAccount({});
    setIsModalOpen(!isModalOpen);
  };

  const startCreateAccount = () => {
    setCurrentAccount({});
    setIsModalOpen(true);
  };

  const startUpdateAccount = (account) => {
    setCurrentAccount(account);
    setIsModalOpen(true);
  };

  const toggleDelete = () => {
    setCurrentAccount({});
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const startDeleteAccount = (account) => {
    setCurrentAccount(account);
    setIsDeleteModalOpen(true);
  };

  function getAccountsUrl(customer_id, template) {
    if (!template) {
      return `/api/v2/customers/${customer_id}/accounts/`;
    } else {
      return `/api/v2/accounts/`;
    }
  }

  const handleSubmit = async (event, account) => {
    event.preventDefault();
    setLoading(true);

    if (account.is_main_account) {
      account.parent = null;
    }

    let url = getAccountsUrl(customer_id, template);

    try {
      let res;
      if (account.id) {
        url += `${account.id}/`;

        res = await axios.patch(url, account);

        if (res.status < 300) {
          setAccountList(
            accountList.map((o) => (o.id === res.data.id ? res.data : o))
          );
          context.fireToast("Success", "Account has been updated", "success");
          toggle();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      } else {
        res = await axios.post(url, account);

        if (res.status < 300) {
          setAccountList([...accountList, res.data]);
          context.fireToast("Success", "Account has been created", "success");
          toggle();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      }
    } catch (err) {
      console.error("There was an error updating the Account!", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (account) => {
    let url = getAccountsUrl(customer_id, template);

    url += `${account.id}/`;

    axios
      .delete(url)
      .then((res) => {
        if (res.status < 300) {
          setAccountList(accountList?.filter((o) => o.id !== account.id));
          context.fireToast("Success", "Account has been deleted", "success");
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
        setLoading(false);
        toggleDelete();
      })
      .catch((err) => console.log(err));
  };

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("file_uploaded", file);

    setLoading(true);
    axios
      .post(
        `/api/v2/customers/${customer_id}/opening_balance_import/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const getData = async () => {
            var accountResults;
            if (template) {
              accountResults = fetchAccounts();
            } else {
              accountResults = fetchCustomerAccounts(customer_id);
            }
            setAccountList(await accountResults);
          };
          getData();

          onUploadClose();
          setLoading(false);
          context.fireToast(
            "Success",
            "Successfully imported opening balances",
            "success"
          );
        } else {
          onUploadClose();
          setLoading(false);
          setError(response.response.data.errors);
          onErrorOpen();
        }
      })
      .catch((error) => {
        console.error("There was an error uploading the file!", error);
        setLoading(false);
        setError(error.response.data.errors);
        onErrorOpen();
      });
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      {loading && (
        <FontAwesomeIcon
          className="float-end fa-spin"
          icon={faSpinner}
          color="green"
        />
      )}
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="100%">
          <AccountModal
            isOpen={isModalOpen}
            toggle={toggle}
            account={currentAccount}
            accountList={accountList}
            accountTypeList={accountTypeList}
            accountSubTypeList={accountSubTypeList}
            accountTaxTypeList={accountTaxTypeList}
            loading={loading}
            handleSubmit={handleSubmit}
          />
          <AccountDeleteModal
            colorScheme="red"
            isOpen={isDeleteModalOpen}
            toggle={toggleDelete}
            account={currentAccount}
            loading={loading}
            handleDelete={handleDelete}
          />
          <UploadModal
            isOpen={isUploadOpen}
            onClose={onUploadClose}
            loading={loading}
            handleUpload={handleUpload}
          />
          <ErrorModal
            isOpen={isErrorOpen}
            onClose={onErrorClose}
            errors={error}
          />
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                Chart of Accounts
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" spacing={6} align="center">
                {!template && (
                  <Button
                    onClick={onUploadOpen}
                    leftIcon={<Upload boxSize={3} />}
                  >
                    Import Opening Balances
                  </Button>
                )}
                <Button
                  onClick={startCreateAccount}
                  leftIcon={<AddIcon boxSize={3} />}
                >
                  Create Account
                </Button>
              </Stack>
            </Box>
          </Flex>
          <Box maxHeight="calc(100vh - 350px)" bg="white" overflowX="auto">
            <Table variant="unstyled">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Number</Th>
                  <Th>Name</Th>
                  {!template && <Th>Current Balance</Th>}
                  <Th>Type</Th>
                  <Th>Sub Type</Th>
                  <Th>TAX Type</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {accountList
                  .filter((a) => a.is_main_account || !a.parent)
                  .map((account) => (
                    <AccountRow
                      key={account.id}
                      account={account}
                      accountList={accountList}
                      accountTypeList={accountTypeList}
                      accountSubTypeList={accountSubTypeList}
                      accountTaxTypeList={accountTaxTypeList}
                      startUpdateAccount={startUpdateAccount}
                      startDeleteAccount={startDeleteAccount}
                      template={template}
                      customer_id={customer_id}
                    />
                  ))}
              </Tbody>
            </Table>
          </Box>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const AccountRow = ({
  account,
  accountList,
  accountTypeList,
  accountSubTypeList,
  accountTaxTypeList,
  startUpdateAccount,
  startDeleteAccount,
  template,
  level = 0,
  customer_id,
}) => {
  var typeText = "";
  var taxTypeText = "";
  const type = accountTypeList.find((at) => at.value === account.type);
  if (type) {
    typeText = type.label;
  }
  var subTypeText = "";
  const subType = accountSubTypeList?.find(
    (at) => at.value === account.sub_type
  );
  if (subType) {
    subTypeText = subType.label;
  }
  const taxType = accountTaxTypeList.find(
    (at) => at.value === account.tax_type
  );
  if (taxType) {
    taxTypeText = taxType.label;
  }

  const isEditable = template || account.customer === customer_id;

  return (
    <>
      <Tr key={account.id}>
        <Td>{account.number}</Td>
        <Td>
          <div style={{ paddingLeft: level * 35 }}>
            {account.number} {account.name}
          </div>
        </Td>
        {!template && <Td>{renderAmount(account.current_balance)}</Td>}
        <Td>{typeText}</Td>
        <Td>{subTypeText}</Td>
        <Td>{taxTypeText}</Td>
        <Td>
          {isEditable && (
            <HStack spacing={2}>
              <Button onClick={() => startUpdateAccount(account)}>Edit</Button>
              <Button
                colorScheme="red"
                onClick={() => startDeleteAccount(account)}
              >
                Delete
              </Button>
            </HStack>
          )}
        </Td>
      </Tr>
      {accountList
        .filter((a) => a.parent === account.id)
        .map((inner_account) => (
          <AccountRow
            account={inner_account}
            accountList={accountList}
            accountTypeList={accountTypeList}
            accountSubTypeList={accountSubTypeList}
            accountTaxTypeList={accountTaxTypeList}
            level={level + 1}
            startUpdateAccount={startUpdateAccount}
            startDeleteAccount={startDeleteAccount}
            template={template}
            customer_id={customer_id}
          />
        ))}
    </>
  );
};

const AccountDeleteModal = ({
  isOpen,
  toggle,
  account,
  loading,
  handleDelete,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete {account.name}?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            onClick={() => handleDelete(account)}
            isDisabled={loading}
          >
            {loading ? "Processing..." : "Delete"}
          </Button>
          <Button onClick={toggle} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const AccountModal = ({
  isOpen,
  toggle,
  account,
  accountList,
  accountTypeList,
  accountSubTypeList,
  accountTaxTypeList,
  loading,
  handleSubmit,
}) => {
  const confirmText = !account?.id ? "Create" : "Update";
  const [accountData, setAccountData] = useState({});

  const formattedAccountList = accountList.map((account) => {
    return {
      value: account.id,
      label: account.number + " - " + account.name,
    };
  });

  useEffect(() => {
    setAccountData(account);
  }, [account]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountData({ ...accountData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAccountData({ ...accountData, [name]: checked });
  };

  const handleSelectParent = (parent) => {
    setAccountData({ ...accountData, parent: parent.value });
  };

  const handleSelectType = (type) => {
    setAccountData({ ...accountData, type: type.value });
  };

  const handleSelectSubType = (sub_type) => {
    setAccountData({ ...accountData, sub_type: sub_type.value });
  };

  const handleSelectTaxType = (taxType) => {
    setAccountData({ ...accountData, tax_type: taxType.value });
  };

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{confirmText} Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="accountForm" onSubmit={(e) => handleSubmit(e, accountData)}>
            <FormControl display="flex" alignItems="center">
              <Checkbox
                name="is_main_account"
                isChecked={accountData.is_main_account}
                onChange={handleCheckboxChange}
              >
                Main Account
              </Checkbox>
            </FormControl>
            {!accountData.is_main_account && (
              <FormControl>
                <FormLabel htmlFor="parent_id">Parent Account</FormLabel>
                <DropDown
                  name="account"
                  options={formattedAccountList}
                  selectedValue={accountData.parent}
                  onChange={handleSelectParent}
                />
              </FormControl>
            )}
            <FormControl>
              <FormLabel htmlFor="number">Number</FormLabel>
              <Input
                type="text"
                name="number"
                defaultValue={accountData.number}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                type="text"
                name="name"
                defaultValue={accountData.name}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="type">Type</FormLabel>
              <TypeSelect
                types={accountTypeList}
                selectedTypeID={accountData.type}
                onChange={handleSelectType}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="sub_type">Sub Type</FormLabel>
              <SubTypeSelect
                type={accountData.type}
                subTypes={accountSubTypeList}
                selectedSubTypeID={accountData.sub_type}
                onChange={handleSelectSubType}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="tax_type">TAX Type</FormLabel>
              <TaxTypeSelect
                taxTypes={accountTaxTypeList}
                selectedTaxTypeID={accountData.tax_type}
                onChange={handleSelectTaxType}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="notes">Description</FormLabel>
              <Textarea
                name="notes"
                defaultValue={accountData.notes}
                onChange={handleInputChange}
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button type="submit" form="accountForm" isDisabled={loading} ml={3}>
            {loading ? <Spinner size="sm" /> : confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const TypeSelect = ({ types, selectedTypeID, onChange }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(types.find((a) => a.value === selectedTypeID));
  }, [types, selectedTypeID]);

  const onChangeLocal = (type) => {
    setSelected(type);
    onChange(type);
  };

  return (
    <Select
      name="account"
      options={types}
      value={selected}
      placeholder={`Select type...`}
      onChange={onChangeLocal}
      useBasicStyles
    />
  );
};

export const SubTypeSelect = ({
  subTypes,
  type,
  selectedSubTypeID,
  onChange,
}) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(subTypes.find((a) => a.value === selectedSubTypeID));
  }, [subTypes, selectedSubTypeID]);

  const onChangeLocal = (type) => {
    setSelected(type);
    onChange(type);
  };

  return (
    <Select
      name="account"
      options={subTypes.filter((s) => s.type === type)}
      value={selected}
      placeholder={`Select sub type...`}
      onChange={onChangeLocal}
      useBasicStyles
    />
  );
};

export const TaxTypeSelect = ({ taxTypes, selectedTaxTypeID, onChange }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(taxTypes.find((a) => a.value === selectedTaxTypeID));
  }, [taxTypes, selectedTaxTypeID]);

  const onChangeLocal = (type) => {
    setSelected(type);
    onChange(type);
  };

  return (
    <Select
      name="taxType"
      options={taxTypes}
      value={selected}
      placeholder={`Select tax type...`}
      onChange={onChangeLocal}
      useBasicStyles
    />
  );
};

const UploadModal = ({ isOpen, onClose, loading, handleUpload }) => {
  const [file, setFile] = useState(null);

  const onUploadFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Opening Balances</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>File</FormLabel>
            <Input
              type="file"
              accept=".xlsx"
              sx={{
                "::file-selector-button": {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: "none",
                  border: "none",
                  fontWeight: "bold",
                },
              }}
              onChange={onUploadFile}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => handleUpload(file)} isDisabled={loading}>
            {loading ? "Processing..." : "Upload"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Errors</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errors &&
            errors.map((e, i) => (
              <>
                <Text key={i} as="b">
                  {e.error}
                </Text>
                {e.detail.map((d, i2) => (
                  <Text key={i + i2} mb={0}>
                    {d}
                  </Text>
                ))}
              </>
            ))}
          {!errors && <Text>Server error, please check template format</Text>}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
