import axios from "axios";

if (process.env.REACT_APP_DOMAIN) {
  axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;
}

// Helper function to retrieve tokens from localStorage or sessionStorage
const getToken = (key) => {
  if (localStorage.getItem(key)) {
    return { token: localStorage.getItem(key), storage: "localStorage" };
  } else if (sessionStorage.getItem(key)) {
    return { token: sessionStorage.getItem(key), storage: "sessionStorage" };
  }
  return { token: null, storage: null };
};

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.request.responseURL.indexOf("/api/v2/token/") > 0) {
      return;
    }
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { token: refreshToken, storage } = getToken("refresh_token");
        if (!refreshToken) {
          throw new Error("No refresh token found.");
        }

        const response = await axios.post("/api/v2/token/refresh/", {
          refresh: refreshToken,
        });

        // Save tokens back to the storage they were retrieved from
        if (storage === "localStorage") {
          localStorage.setItem("access_token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);
        } else if (storage === "sessionStorage") {
          sessionStorage.setItem("access_token", response.data.access);
          sessionStorage.setItem("refresh_token", response.data.refresh);
        }

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        return axios(originalRequest);
      } catch (error) {
        console.log("Refresh token failed:", error);
        // Handle refresh token error or redirect to login
        localStorage.clear();
        sessionStorage.clear();
        window.dispatchEvent(new Event("storage"));
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const { token } = getToken("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
