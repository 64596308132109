import { Box, Card, CardBody, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";

const ExpenseCard = ({
  title = "",
  percentage = "0.00",
  total = "0.00",
  perfectPL = "0.00",
  color = "black",
}) => {
  return (
    <Stack width="100%" align="center" p="10px 10px" fontFamily={"lato"}>
      <Card width="100%" height="143px" borderRadius="12px">
        <CardBody padding={"12px"}>
          <Box align={"center"} p={0}>
            <Text fontSize={"16px"} margin={0} p={0} color={"#202224"}>

              {title}
            </Text>
            <Text
              fontSize={"clamp(28px, 5vw, 32px)"}
              fontWeight={600}
              margin={0}
              p={0}
              color={color}
            >
              {percentage}
            </Text>
          </Box>
          <Box height={"30px"}>
            <SimpleGrid templateColumns={"1fr 1fr"} align="center">
              <Box p={0} borderRightWidth={"2px"} height={"100%"} m={0}>
                <Text fontSize={"14px"} margin={0} gridColumn={1} gridRow={1} color={"#202224"}>
                  Perfect P&L
                </Text>
                <Text
                  fontSize={"clamp(12px, 5vw, 17px)"}
                  fontWeight={600}
                  margin={0}
                  gridColumn={1}
                  gridRow={2}
                  whiteSpace={"nowrap"}
                >
                  Below {perfectPL}
                </Text>
              </Box>
              <Box p={0}>
                <Text fontSize={"14px"} margin={0} gridColumn={2} gridRow={1} color={"#202224"}>
                  Total
                </Text>
                <Text
                  fontSize={"clamp(12px, 5vw, 17px)"}
                  fontWeight={600}
                  margin={0}
                  gridColumn={2}
                  gridRow={2}
                  whiteSpace={"nowrap"}
                >
                  {total}
                </Text>
              </Box>
            </SimpleGrid>
          </Box>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default ExpenseCard;
