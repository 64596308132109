import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClientUpdate from "./clientUpdate";
import { ClientCreate } from "./clientCreate";
import {
  Image,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  Stack,
  Container,
  Heading,
  Input,
  Flex,
  Box,
  Spacer,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CustomTableContainer from "../../theme/components/tableContainer";
import { Context } from "../../ContextWrapper";
import { ClientCurrentStatus } from "./clientCurrentStatus";
import { useCurrentProfile } from "../../ContextWrapper";
import { fetchCustomers } from "../../api/customers";
import { AccessToken } from "../../utils";

export const ClientList = () => {
  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = React.useState("");
  const context = React.useContext(Context);
  const { profile } = useCurrentProfile();

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredCustomers = customerList.filter(
    (item) =>
      item.name.toUpperCase().includes(search.toUpperCase()) ||
      item.email.toUpperCase().includes(search.toUpperCase())
  );

  useEffect(() => {
    let accessToken = AccessToken()
    if (accessToken === null) {
      window.location.href = "/login";
    } else {
      const fetchData = async () => {
        const results = await fetchCustomers(profile);
        setCustomerList(results);
      };
      fetchData();
    }
  }, [profile]);

  const addCustomerToList = (customer) => {
    setCustomerList((prevList) => [...prevList, customer]);
    context.fireToast("Success", "Client created.", "success");
  };

  const updateCustomerInList = (updatedCustomer) => {
    setCustomerList((prevList) =>
      prevList.map((customer) =>
        customer.id === updatedCustomer.id ? updatedCustomer : customer
      )
    );
    context.fireToast("Success", "Client updated.", "success");
  };

  const removeCustomerFromList = (customerId) => {
    setCustomerList((prevList) =>
      prevList.filter((customer) => customer.id !== customerId)
    );

    context.fireToast("Success", "Client deleted.", "success");
  };

  const renderCustomers = () => {
    return filteredCustomers.map((customer) => (
      <Tr key={customer.id}>
        <Td style={{ paddingRight: "0px" }}>
          <Box display="inline-flex" alignItems="center">
            <Image
              src={
                customer.logo ||
                `https://ui-avatars.com/api/?size=24&name=${customer.name}`
              }
              alt={customer.name || "Placeholder"}
              boxSize="25px"
              objectFit="cover"
              borderRadius="full"
              mr="2"
            />
            {customer.name}
          </Box>
        </Td>
        <Td>{customer.email}</Td>
        <Td>{customer.primary_contact}</Td>
        <Td>
          <ClientCurrentStatus customer={customer} />
        </Td>
        <Td pt="1" pb="1">
          <Stack p="0" direction="row" spacing={2} align="center">
            <ClientUpdate
              customer={customer}
              updateCustomerInList={updateCustomerInList}
              removeCustomerFromList={removeCustomerFromList}
            />
            <Link to={`/client/${customer.id}`}>
              <Button>View</Button>
            </Link>
          </Stack>
        </Td>
      </Tr>
    ));
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="90%">
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                Client Listing
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" spacing={6} align="center">
                <ClientCreate addCustomerToList={addCustomerToList} />
                <InputGroup minW="340px">
                  <Input placeholder="Search..." onChange={handleSearch} />
                  <InputRightElement
                    bg="brand.500"
                    borderBottomRightRadius={6}
                    borderTopRightRadius={6}
                  >
                    <SearchIcon variant="white" />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Box>
          </Flex>
          <Box maxHeight="calc(100vh - 300px)" bg="white" overflowX="auto">
            <Table variant="unstyled" width="100%">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Business Name</Th>
                  <Th>Email Address</Th>
                  <Th>Primary Contact</Th>
                  <Th>Current Status</Th>
                  <Th style={{ width: "118px" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>{renderCustomers()}</Tbody>
            </Table>
          </Box>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};
