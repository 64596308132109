import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import CustomTableContainer from "../../theme/components/tableContainer";
import { AddIcon } from "@chakra-ui/icons";
import { DropDown, getChoiceFieldValues } from "../generic/dropDown";
import { fetchCustomerAccounts } from "../../api/accounts";
import { VendorSelect } from "../account/vendorDropDown";
import { fetchPlaiditems } from "../../api/plaid";
import { Plus, ToggleLeft, ToggleRight } from "react-feather";
import { Context } from "../../ContextWrapper";

export const ClientBankRules = ({ customer_id }) => {
  const [bankRuleList, setBankRuleList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [plaidItemList, setPlaidItemList] = useState([]);
  const [options, setOptions] = useState({});
  const [rule, setRule] = useState(null);
  const {
    isOpen: isRuleOpen,
    onOpen: onRuleOpen,
    onClose: onRuleClose,
  } = useDisclosure();
  const context = React.useContext(Context);

  const refreshBankRuleList = (customer_id) => {
    if (customer_id) {
      axios
        .get(
          `/api/v2/customers/${customer_id}/bankrules/`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setBankRuleList(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    refreshBankRuleList(customer_id);
    getChoiceFieldValues(`/api/v2/customers/${customer_id}/bankrules/`).then(
      (r) => setOptions(r)
    );
    const getData = async () => {
      var accountResults = fetchCustomerAccounts(customer_id, {}, true);
      setAccountList(await accountResults);

      var plaidItemsResults = fetchPlaiditems(customer_id);
      setPlaidItemList(await plaidItemsResults);
    };
    getData();
  }, [customer_id]);

  const bankAccounts = plaidItemList
    ?.map((item) =>
      item.bankaccount_set.map((bankAccount) => {
        return {
          value: bankAccount.id,
          label: bankAccount.name,
        };
      })
    )
    .flat();

  const createRule = () => {
    setRule({ bankaccounts: [], conditions: [] });
    onRuleOpen();
  };

  const editRule = (selectedRule) => {
    setRule(selectedRule);
    onRuleOpen();
  };

  const handleSubmit = (event, data) => {
    event.preventDefault();

    data.conditions = data.conditions?.map((c) => {
      if (typeof c.id === "string") {
        delete c.id;
      }
      return c;
    });

    if (data.bankaccounts.length <= 0) {
      context.fireToast(
        "Error",
        "Please select at least one bank account.",
        "error"
      );
      return;
    }

    if (data.conditions.length <= 0) {
      context.fireToast("Error", "Please add at least one condition.", "error");
      return;
    }

    const allConditionsValid = data.conditions?.every(
      (c) => c.field && c.operator && c.value
    );

    if (!allConditionsValid) {
      context.fireToast("Error", "All conditions not valid.", "error");
      return;
    }

    if (data.id) {
      axios
        .patch(
          `/api/v2/customers/${customer_id}/bankrules/${data.id}/`,
          data,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setBankRuleList(
            bankRuleList.map((o) => (o.id === res.data.id ? res.data : o))
          );
          onRuleClose();
          context.fireToast("Success", "Bank rule updated.", "success");
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(
          `/api/v2/customers/${customer_id}/bankrules/`,
          data,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setBankRuleList([...bankRuleList, res.data]);
          onRuleClose();
          context.fireToast("Success", "Bank rule created.", "success");
        })
        .catch((err) => console.log(err));
    }
  };

  const getAccountName = (accountId) => {
    for (let account of accountList) {
      if (account.value === accountId) {
        return account.label;
      }
    }
  };

  const toggleRule = (rule) => {
    axios
      .patch(`/api/v2/customers/${customer_id}/bankrules/${rule.id}/`, {
        is_active: !rule.is_active,
      })
      .then((res) => {
        setBankRuleList(
          bankRuleList.map((o) => (o.id === res.data.id ? res.data : o))
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="100%">
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                Bank Rules (Under construction)
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" spacing={6} align="center">
                <Button onClick={createRule} leftIcon={<AddIcon boxSize={3} />}>
                  Create Rule
                </Button>
              </Stack>
            </Box>
          </Flex>
          <Box maxHeight="calc(100vh - 350px)" bg="white" overflowX="auto">
            <Table variant="unstyled">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Priority</Th>
                  <Th>Name</Th>
                  <Th>Account</Th>
                  <Th>Vendor</Th>
                  <Th>Active</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {bankRuleList?.map((rule) => (
                  <Tr key={rule.id}>
                    <Td>{rule.priority}</Td>
                    <Td>{rule.name}</Td>
                    <Td>{getAccountName(rule.account)}</Td>
                    <Td>{rule.vendor.name}</Td>
                    <Td>
                      {rule.is_active && (
                        <ToggleRight
                          color="green"
                          onClick={() => toggleRule(rule)}
                        />
                      )}
                      {!rule.is_active && (
                        <ToggleLeft
                          color="red"
                          onClick={() => toggleRule(rule)}
                        />
                      )}
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button onClick={() => editRule(rule)}>Edit</Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          {isRuleOpen && (
            <BankRule
              rule={rule}
              handleSubmit={handleSubmit}
              isOpen={isRuleOpen}
              onClose={onRuleClose}
              options={options}
              accountList={accountList}
              bankAccounts={bankAccounts}
            />
          )}
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const BankRule = ({
  rule,
  handleSubmit,
  isOpen,
  onClose,
  options,
  accountList,
  bankAccounts,
}) => {
  const confirmText = !rule?.id ? "Create" : "Update";
  const [ruleData, setRuleData] = useState({});
  const [tempID, setTempID] = useState(1);

  useEffect(() => {
    setRuleData(rule);
  }, [rule]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRuleData({ ...ruleData, [name]: value });
  };

  const handleSelect = (selected, name) => {
    setRuleData({ ...ruleData, [name]: selected.value });
  };

  const handleConditionInputChange = (event, condition) => {
    const name = "conditions";
    const conditions = ruleData.conditions.map((e) => {
      if (e.id === condition.id) {
        e.value = event.target.value;
      }
      return e;
    });
    setRuleData({ ...ruleData, [name]: conditions });
  };

  const toggleCondition = (condition) => {
    const name = "conditions";
    const conditions = ruleData.conditions.map((e) => {
      if (e.id === condition.id) {
        e.is_active = !condition.is_active;
      }
      return e;
    });
    setRuleData({ ...ruleData, [name]: conditions });
  };

  const handleConditionSelect = (selected, { condition, name }) => {
    let conditions = ruleData.conditions.filter((c) => c.id !== condition.id);
    conditions.push({ ...condition, [name]: selected.value });
    setRuleData({ ...ruleData, conditions });
  };

  const handleBankaccountCheck = (value, checked) => {
    const name = "bankaccounts";
    let accounts = ruleData.bankaccounts || [];
    if (checked) {
      accounts.push(value);
    } else {
      accounts = accounts.filter((e) => e !== value);
    }
    setRuleData({ ...ruleData, [name]: accounts });
  };

  const addCondition = () => {
    let conditions = ruleData.conditions;
    conditions.push({ id: "temp_" + tempID, value: "", is_active: true });
    setTempID((prev) => prev + 1);
    setRuleData({ ...ruleData, conditions });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{confirmText} Rule</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="ruleForm" onSubmit={(e) => handleSubmit(e, ruleData)}>
            <HStack>
              <FormControl id="priority" isRequired w={"100px"}>
                <FormLabel htmlFor="priority">Priority</FormLabel>
                <Input
                  name="priority"
                  onChange={handleInputChange}
                  defaultValue={ruleData.priority}
                  type="number"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input
                  name="name"
                  onChange={handleInputChange}
                  defaultValue={ruleData.name}
                />
              </FormControl>
            </HStack>
            <HStack pt={5}>
              <FormControl id="account">
                <FormLabel>Auto add Account</FormLabel>
                <DropDown
                  name="account"
                  options={accountList}
                  onChange={handleSelect}
                  metaData={"account"}
                  selectedValue={ruleData.account}
                />
              </FormControl>
              <FormControl id="vendor_id">
                <FormLabel>Auto add Vendor</FormLabel>
                <VendorSelect
                  selectedVendorID={ruleData.vendor_id || ruleData.vendor?.id}
                  onChange={handleSelect}
                  metaData={"vendor_id"}
                  w={"600px"}
                />
              </FormControl>
            </HStack>

            <FormControl id="bankaccounts" pt={5}>
              <FormLabel>Bank Accounts</FormLabel>
              <HStack>
                {bankAccounts.map((b) => {
                  return (
                    <Checkbox
                      key={b.value}
                      value={b.value}
                      isChecked={ruleData.bankaccounts?.includes(b.value)}
                      onChange={(e) =>
                        handleBankaccountCheck(e.target.value, e.target.checked)
                      }
                    >
                      {b.label}
                    </Checkbox>
                  );
                })}
              </HStack>
            </FormControl>

            <HStack pt={5}>
              <FormControl id="direction">
                <FormLabel>Direction</FormLabel>
                <DropDown
                  name="direction"
                  options={options.direction}
                  selectedValue={ruleData.direction}
                  onChange={handleSelect}
                  metaData={"direction"}
                />
              </FormControl>
              <FormControl id="match">
                <FormLabel>Match</FormLabel>
                <DropDown
                  name="match"
                  options={options.match}
                  selectedValue={ruleData.match}
                  onChange={handleSelect}
                  metaData={"match"}
                />
              </FormControl>
            </HStack>
            <FormControl id="conditions" pt={5}>
              <FormLabel>Conditions</FormLabel>
              <Table variant="unstyled" size="sm" pt={5}>
                <Thead>
                  <Tr>
                    <Td>Field</Td>
                    <Td>Operator</Td>
                    <Td>Value</Td>
                    <Td>Active</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {ruleData.conditions?.map((condition) => (
                    <Tr key={condition.id}>
                      <Td pt="4px" pb="4px">
                        <DropDown
                          name="field"
                          options={options.field}
                          selectedValue={condition.field}
                          onChange={handleConditionSelect}
                          metaData={{ condition, name: "field" }}
                        />
                      </Td>
                      <Td pt="4px" pb="4px">
                        <DropDown
                          name="operator"
                          options={options.operator}
                          selectedValue={condition.operator}
                          onChange={handleConditionSelect}
                          metaData={{ condition, name: "operator" }}
                        />
                      </Td>
                      <Td>
                        <Input
                          type="text"
                          value={condition.value}
                          onChange={(event) =>
                            handleConditionInputChange(event, condition)
                          }
                        />
                      </Td>
                      <Td>
                        {condition.is_active && (
                          <ToggleRight
                            color="green"
                            onClick={() => toggleCondition(condition)}
                          />
                        )}
                        {!condition.is_active && (
                          <ToggleLeft
                            color="red"
                            onClick={() => toggleCondition(condition)}
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Button
                rightIcon={<Plus />}
                variant={"ghost"}
                onClick={addCondition}
              >
                Add condition
              </Button>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" form="ruleForm">
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
