import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { FaLock, FaUserAlt } from "react-icons/fa";
import { Context } from "../ContextWrapper";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const context = React.useContext(Context);

  const handleShowClick = () => setShowPassword(!showPassword);

  const submit = async (e) => {
    e.preventDefault();
    const user = {
      username: username,
      password: password,
    };

    try {
      const res = await axios.post("/api/v2/token/", user, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (res.status === 200) {
        localStorage.clear();
        sessionStorage.clear();

        // Save tokens based on "Keep Me Signed In"
        if (keepMeSignedIn) {
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
        } else {
          sessionStorage.setItem("access_token", res.data.access);
          sessionStorage.setItem("refresh_token", res.data.refresh);
        }

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.access}`;

        window.location.href = "/";
      }
    } catch (error) {
      context.fireToast("Error", "Invalid credentials.", "error");
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
      minHeight={"500px"}
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={"100%"}>
          <form onSubmit={submit}>
            <Stack
              spacing={4}
              p="1.75rem"
              width={"90vw"}
              maxW={"438px"}
              backgroundColor="#FFFFFF"
              boxShadow="0px 8px 16px 0px #00000026"
              borderRadius={"5px"}
              alignItems={"center"}
            >
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Text fontSize={"24px"} fontWeight={400}>
                  Welcome to
                </Text>
                <Image
                  width={"221px"}
                  src="lucro-logo-light.png"
                  alt="Dan Abramov"
                />
              </Box>
              <FormControl w={"80vw"} maxW={"374px"}>
                <Text fontWeight={500} fontSize={"16px"} mb={2}>
                  Email
                </Text>
                <InputGroup borderRadius={"4px"}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="#0A101B" />}
                  />
                  <Input
                    type="text"
                    placeholder="Enter email address"
                    value={username}
                    required
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl w={"80vw"} maxW={"374px"} mb={3}>
                <Text fontWeight={500} fontSize={"16px"} mb={2}>
                  Password
                </Text>
                <InputGroup borderRadius={"4px"}>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="#0A101B" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="50px">
                    <Button
                      h="15px"
                      w={"20px"}
                      backgroundColor={"#FFFFFF"}
                      _hover={{ bg: "#FFFFFF" }}
                      color={"#0A101B"}
                      p={0}
                      onClick={handleShowClick}
                    >
                      {showPassword ? <EyeOff></EyeOff> : <Eye></Eye>}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl w={"80vw"} maxW={"374px"}>
                <Flex>
                  <Checkbox
                    sx={{
                      ".chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        borderWidth: "3px",
                        borderColor: "#0A101B",
                      },
                      ".chakra-checkbox__icon": {
                        fontSize: "16px",
                      },
                    }}
                    onChange={() => setKeepMeSignedIn(!keepMeSignedIn)}
                  ></Checkbox>
                  <Text pl={2} fontWeight={500} fontSize={"16px"}>
                    Keep me signed in
                  </Text>
                </Flex>
              </FormControl>
              <Button
                mt={3}
                borderRadius={"4px"}
                type="submit"
                variant="solid"
                _hover={{ bg: "#535DEC" }}
                backgroundColor={"#536DEC"}
                w={"80vw"}
                maxW={"374px"}
              >
                Log in
              </Button>
              <FormControl w={"80vw"} maxW={"374px"} mb={2}>
                <FormHelperText
                  textAlign="center"
                  fontSize={"16px"}
                  fontWeight={400}
                  color={"#234E94"}
                >
                  <Link href="/forgotPassword">Reset password</Link>
                </FormHelperText>
              </FormControl>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
