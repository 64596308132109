import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Context } from "../ContextWrapper";

const CFaUserAlt = chakra(FaUserAlt);

export const ForgotPassword = () => {
  const context = React.useContext(Context);
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const user = {
      email: email,
    };
    await axios
      .post(
        "/api/v2/forgotpassword/",
        user,
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setDisabled(true);
        context.fireToast(
          "Reset password",
          "Password change email sent.",
          "success"
        );
      });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
      minHeight={"500px"}
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={"100%"}>
          <form onSubmit={submit}>
            <Stack
              spacing={4}
              p="1.75rem"
              width={"90vw"}
              maxW={"438px"}
              backgroundColor="#FFFFFF"
              boxShadow="0px 8px 16px 0px #00000026"
              borderRadius={"5px"}
              alignItems={"center"}
            >
              <Box
                w="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Text fontSize={"24px"} fontWeight={400}>
                  Welcome to
                </Text>
                <Image
                  width={"221px"}
                  src="lucro-logo-light.png"
                  alt="Dan Abramov"
                />
              </Box>
              <FormControl w={"80vw"} maxW={"374px"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={400}
                  mb={4}
                  textAlign={"center"}
                >
                  Enter your email and we'll send you a link to reset your
                  password.
                </Text>
                <Text fontWeight={500} fontSize={"16px"} mb={2}>
                  Email
                </Text>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="#0A101B" />}
                  />
                  <Input
                    type="text"
                    placeholder="Enter email address"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>

              <Button
                mt={4}
                borderRadius={"4px"}
                type="submit"
                variant="solid"
                _hover={{ bg: "#535DEC" }}
                backgroundColor={"#536DEC"}
                w={"80vw"}
                maxW={"374px"}
                isDisabled={disabled}
              >
                Submit
              </Button>
              <FormControl>
                <FormHelperText
                  fontSize={"16px"}
                  fontWeight={400}
                  color={"#234E94"}
                  textAlign="center"
                >
                  <Link href="/login">Back to login</Link>
                </FormHelperText>
              </FormControl>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
