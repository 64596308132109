import { useEffect } from "react";
import axios from "axios";
export const Logout = () => {
  useEffect(() => {
    (async () => {
      try {
        // Get the refresh token from localStorage or sessionStorage
        const refreshToken =
          localStorage.getItem("refresh_token") ||
          sessionStorage.getItem("refresh_token");

        if (refreshToken) {
          await axios.post(
            "/api/v2/logout/",
            { refresh_token: refreshToken },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );

          // Clear both localStorage and sessionStorage
          localStorage.clear();
          sessionStorage.clear();

          // Reset Axios default Authorization header
          axios.defaults.headers.common["Authorization"] = null;

          // Redirect to the login page
          window.location.href = "/login";
        } else {
          console.log("No refresh token found in storage.");
        }
      } catch (e) {
        console.log("Logout not working", e);
      }
    })();
  }, []);
  return <div></div>;
};
