import { Alert, AlertDescription, AlertIcon, AlertTitle, Link } from "@chakra-ui/react";
import { useUpdateCheck } from "react-update-notification";

const NotificationContainer = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 20000,
  });

  if (status === "checking" || status === "current") {
    return null;
  }

  return (
    <Alert zIndex={1001} status="info">
      <AlertIcon />
      <AlertTitle>New version available!</AlertTitle>
      <AlertDescription>
        Please <Link onClick={reloadPage} textDecoration={"underline"}>click here</Link> to reload.
      </AlertDescription>
    </Alert>
  );
};

export default NotificationContainer;
