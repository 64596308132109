import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Container,
  Box,
  Flex,
  Stack,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Text,
  Spacer,
  Td,
  Input,
  ModalFooter,
  IconButton,
  HStack,
  NumberInput,
  NumberInputField,
  Checkbox,
  Center,
} from "@chakra-ui/react";
import { BankAccountCard } from "../account/bankAccountCard";
import CustomTableContainer from "../../theme/components/tableContainer";
import { VendorSelect } from "../account/vendorDropDown";
import { LoadingTable, oppositeType, renderAmount } from "../../utils";
import { Trash2, Plus, ChevronDown, ChevronUp } from "react-feather";
import { Context } from "../../ContextWrapper";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { DropDown } from "../generic/dropDown";
import { TransactionList } from "../account/transactionList";
import { fetchCustomerAccounts } from "../../api/accounts";

export const ClientBankTransactions = ({ customer_id }) => {
  const [plaidItemList, setPlaidItemList] = useState([]);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const [accountList, setAccountList] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isTransferOpen,
    onOpen: onTransferOpen,
    onClose: onTransferClose,
  } = useDisclosure();
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursors, setPreviousCursors] = useState([]);
  const [balances, setBalances] = useState([]);
  const [ordering, setOrdering] = useState("date");
  const [includeExcluded, setIncludeExcluded] = useState(false);

  useEffect(() => {
    refreshPlaidItemList(customer_id);
    refreshAccountList(customer_id);
    setLoading(false);
  }, [customer_id]);

  useEffect(() => {
    if (plaidItemList && plaidItemList.length > 0) {
      setSelectedItem(plaidItemList[0]);

      setBalances(
        plaidItemList
          .map((item) =>
            item.bankaccount_set.map((bankAccount) => {
              return {
                id: bankAccount.accounting_account?.id,
                balance: bankAccount.accounting_account?.current_balance,
              };
            })
          )
          .flat()
      );
    }
  }, [plaidItemList]);

  useEffect(() => {
    if (selectedItem) {
      setBankAccountList(
        selectedItem.bankaccount_set.filter(
          (a) => a.accounting_account !== null
        )
      );
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!selectedBankAccount && bankAccountList.length > 0) {
      setSelectedBankAccount(bankAccountList[0]);
    }
  }, [bankAccountList, selectedBankAccount]);

  const refreshPlaidItemList = (id) => {
    if (id) {
      setLoading(true);
      axios
        .get(`/api/v2/customers/${id}/plaiditems`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((res) => {
          setPlaidItemList(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const refreshAccountList = (customer_id) => {
    if (customer_id) {
      const getData = async () => {
        var accountResults = fetchCustomerAccounts(customer_id, {}, true);
        setAccountList(await accountResults);
      };
      getData();
    }
  };

  const refreshTransactionList = useCallback(
    (bankaccount_id, cursor = null) => {
      if (bankaccount_id) {
        setLoading(true);

        let cursorParam = null;
        if (cursor) {
          const urlObj = new URL(cursor);
          cursorParam = urlObj.searchParams.get("cursor");
        }

        let params = {
          cursor: cursorParam,
          ordering: ordering,
          exclude_added: true,
          date_after: `${new Date().getFullYear()}-01-01`,
        };

        if (!includeExcluded) {
          params.is_excluded = false;
        }

        axios
          .get(`/api/v2/bankaccounts/${bankaccount_id}/transactions/`, {
            headers: { "Content-Type": "application/json" },
            params: params,
            withCredentials: true,
          })
          .then((res) => {
            setTransactions(res.data.results);
            setNextCursor(res.data.next);
            setPreviousCursors(res.data.previous);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    },
    [ordering, includeExcluded]
  );

  useEffect(() => {
    if (selectedBankAccount) {
      refreshTransactionList(selectedBankAccount.id);
    }
  }, [selectedBankAccount, refreshTransactionList]);

  const replaceTransaction = (t, action = "replace") => {
    if (action === "remove") {
      var removes = [t];
      if (t.constructor === Array) {
        removes = t;
      }
      removes.map((transaction) => {
        setTransactions((prev) => prev.filter((o) => o.id !== transaction.id));
        var current = selectedBankAccount;
        current.transaction_review_count = current.transaction_review_count - 1;
        setBankAccountList((prev) =>
          prev.map((a) => (a.id === current.id ? current : a))
        );
        return transaction;
      });
    } else {
      if (t.constructor === Array) {
        t.map((transaction) =>
          setTransactions((prev) =>
            prev.map((o) => (o.id === transaction.id ? transaction : o))
          )
        );
      } else {
        setTransactions(transactions.map((o) => (o.id === t.id ? t : o)));
      }
    }
  };

  const selectBankAccount = (item, bankAccount) => {
    setLoading(true);
    setOrdering("date");
    setSelectedItem(item);
    setSelectedBankAccount(bankAccount);
    setPreviousCursors([]);
  };

  const splitTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    onOpen();
  };

  const linkTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    onTransferOpen();
  };

  const handleNextPage = () => {
    if (nextCursor) {
      refreshTransactionList(selectedBankAccount.id, nextCursor);
    }
  };

  const handlePreviousPage = () => {
    const previousCursor = previousCursors;
    if (previousCursor) {
      refreshTransactionList(selectedBankAccount.id, previousCursor);
    }
  };

  useEffect(() => {
    const updateAccount = (account_id, balance) => {
      setBalances((balances) => {
        return balances.map((account) => {
          if (account.id === account_id) {
            return {
              ...account,
              balance: balance,
            };
          } else {
            return account;
          }
        });
      });
    };

    var host = window.location.host;
    if (host.indexOf(":3000") === -1) {
      host = "wss://" + host;
    } else {
      host = "ws://" + host.replace(":3000", ":8000");
    }
    const wsClient = new W3CWebSocket(
      `${host}/ws/${customer_id}/accountbalance/`
    );
    wsClient.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    wsClient.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      if (dataFromServer) {
        const { account_id, balance } = dataFromServer.text;

        updateAccount(account_id, balance);
      }
    };
  }, [customer_id]);

  const getAccountingBalance = (account_id) => {
    if (!account_id) {
      return null;
    }
    return balances.find((account) => account.id === account_id)?.balance;
  };

  const updateOrdering = (column) => {
    if (ordering.indexOf(column) !== -1) {
      if (ordering.indexOf("-") !== -1) {
        setOrdering(column);
      } else {
        setOrdering(`-${column}`);
      }
    } else {
      setOrdering(column);
    }
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <>
        {selectedTransaction && isOpen && (
          <JournalSplit
            transaction={selectedTransaction}
            accountList={accountList}
            isOpen={isOpen}
            onClose={onClose}
            bankAccount={selectedBankAccount}
            replaceTransaction={replaceTransaction}
            customer_id={customer_id}
          />
        )}
        {selectedTransaction && isTransferOpen && (
          <TransferLink
            transaction={selectedTransaction}
            isOpen={isTransferOpen}
            onClose={onTransferClose}
            customer_id={customer_id}
            bankAccount={selectedBankAccount}
            plaidItemList={plaidItemList}
            replaceTransaction={replaceTransaction}
          />
        )}
        <Flex overflowX="scroll" w={"calc(100vw - 369px)"}>
          {plaidItemList &&
            plaidItemList.map((item) =>
              item.bankaccount_set.map((bankAccount) => (
                <BankAccountCard
                  key={bankAccount.id}
                  onClick={() => selectBankAccount(item, bankAccount)}
                  bankName={item.name}
                  bankAccount={bankAccount}
                  balance={getAccountingBalance(
                    bankAccount.accounting_account?.id
                  )}
                  variant={
                    selectedBankAccount &&
                    bankAccount.id === selectedBankAccount.id
                      ? "selected"
                      : null
                  }
                />
              ))
            )}
        </Flex>
        {plaidItemList && plaidItemList.length === 0 && (
          <div>Link bank accounts on the Plaid menu</div>
        )}
        {plaidItemList &&
          plaidItemList.length > 0 &&
          bankAccountList.length === 0 && (
            <div>Link the bank accounts to accounts on the Plaid menu</div>
          )}
        {bankAccountList.length > 0 && (
          <CustomTableContainer>
            <Box
              maxHeight="calc(100vh - 450px)"
              m="4"
              bg="white"
              overflowX="auto"
            >
              {loading && <LoadingTable />}
              {!loading && (
                <Table variant="unstyled" width="100%">
                  <Thead position="sticky" top={-1} zIndex={1}>
                    <Tr>
                      <Td w="40px"></Td>
                      <ThOrder
                        header="date"
                        field="date"
                        ordering={ordering}
                        updateOrdering={updateOrdering}
                      />
                      <ThOrder
                        header="description"
                        field="name"
                        ordering={ordering}
                        updateOrdering={updateOrdering}
                      />
                      <Th>Account</Th>
                      <Th>Vendor</Th>
                      <Th>Spent</Th>
                      <Th>Received</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <TransactionList
                      transactions={transactions}
                      bankAccount={selectedBankAccount}
                      accountList={accountList}
                      replaceTransaction={replaceTransaction}
                      splitTransaction={splitTransaction}
                      linkTransaction={linkTransaction}
                      customer_id={customer_id}
                      includeExcluded={includeExcluded}
                    />
                  </Tbody>
                </Table>
              )}
            </Box>
            <Stack direction="row" spacing={4} mt={4} justifyContent="center">
              {previousCursors && (
                <Button onClick={handlePreviousPage}>Previous</Button>
              )}
              {nextCursor && (
                <Button onClick={handleNextPage} disabled={!nextCursor}>
                  Next
                </Button>
              )}
              <Checkbox
                name="includeExcluded"
                isChecked={includeExcluded}
                onChange={() => setIncludeExcluded(!includeExcluded)}
                fontWeight={"normal"}
              >
                Show excluded
              </Checkbox>
            </Stack>
          </CustomTableContainer>
        )}
      </>
    </Container>
  );
};

const ThOrder = ({ header, field, ordering, updateOrdering }) => {
  return (
    <Th onClick={() => updateOrdering(field)}>
      <HStack>
        <Text m={"0"}>{header}</Text>
        {ordering === `-${field}` && <ChevronDown />}
        {ordering === field && <ChevronUp />}
      </HStack>
    </Th>
  );
};

const TransferLink = ({
  transaction,
  isOpen,
  onClose,
  customer_id,
  bankAccount,
  plaidItemList,
  replaceTransaction,
}) => {
  const [accountList, setAccountList] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const accounts = plaidItemList
      .flatMap((item) => {
        for (var i in item.bankaccount_set) {
          item.bankaccount_set[i].item_name = item.name;
        }
        return item.bankaccount_set;
      })
      .filter((a) => a.accounting_account && a.id !== bankAccount.id)
      .map((a) => ({ value: a.id, label: a.item_name + ": " + a.name }));
    setAccountList(accounts);
  }, [plaidItemList, bankAccount]);

  useEffect(() => {
    if (selectedBankAccount) {
      let start = new Date(Date.parse(transaction.date));
      start.setDate(start.getDate() - 15);
      start = start.toISOString().split("T")[0];

      let end = new Date(Date.parse(transaction.date));
      end.setDate(end.getDate() + 15);
      end = end.toISOString().split("T")[0];

      axios
        .get(`/api/v2/bankaccounts/${selectedBankAccount}/transactions/`, {
          headers: { "Content-Type": "application/json" },
          params: {
            ordering: "date",
            date_after: start,
            date_before: end,
            amount: transaction.amount * -1,
            exclude_added: true,
          },
          withCredentials: true,
        })
        .then((res) => {
          setTransactions(res.data.results);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedBankAccount, transaction]);

  const submitTransfer = () => {
    axios
      .post(`/api/v2/customers/${customer_id}/linktransfer/`, {
        bankaccount_id: bankAccount.id,
        transaction_id: transaction.id,
        link_bankaccount_id: selectedBankAccount,
        link_transaction_id: selectedTransaction?.id,
      })
      .then((res) => {
        replaceTransaction(transaction, "remove");
        onClose();
      })
      .catch((err) => console.log(err));
  };

  const updateTransferAccount = (account) => {
    setSelectedBankAccount(account.value);
  };

  const selectTransaction = (t, e) => {
    if (e.target.checked) {
      setSelectedTransaction(t);
    } else {
      setSelectedTransaction(null);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxH="800px" maxW="1000px">
        <ModalHeader>Split Transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center pb="25px">
            <DropDown
              name="account"
              options={accountList}
              onChange={updateTransferAccount}
              w="400px"
            />
          </Center>
          <Table variant="unstyled">
            <Tbody>
              {transactions &&
                transactions.map((t) => (
                  <Tr key={t.id}>
                    <Td>
                      <Checkbox
                        isChecked={t.id === selectedTransaction?.id}
                        onChange={(e) => selectTransaction(t, e)}
                      />
                    </Td>
                    <Td>{t.date}</Td>
                    <Td>{t.name}</Td>
                    <Td>{t.amount}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          {selectedBankAccount && transactions.length === 0 && (
            <Center>
              <Text pt={"3"}>No transactions found.</Text>
            </Center>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={submitTransfer}>Link Transfer</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const JournalSplit = ({
  transaction,
  accountList,
  isOpen,
  onClose,
  bankAccount,
  replaceTransaction,
  customer_id,
}) => {
  const [journalEntries, setJournalEntries] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [index, setIndex] = useState(1);
  const [total, setTotal] = useState(0.0);
  const context = React.useContext(Context);

  const account_type = bankAccount.accounting_account.account_type;
  const bank_account_type =
    transaction.amount <= 0 ? account_type : oppositeType(account_type);

  const generateBlankJournalEntry = useCallback(
    (index, amount, type) => {
      return {
        id: null,
        index: index,
        date: transaction.date,
        account: null,
        entry_type: type,
        description: "",
        amount: Math.abs(amount),
      };
    },
    [transaction]
  );

  useEffect(() => {
    setTotal(0.0);
    if (transaction.journal_entries.length > 0) {
      const entries = transaction.journal_entries.map((e, i) => {
        e.index = i;
        return e;
      });
      setIndex(entries.length + 1);
      setJournalEntries(entries);
    } else {
      const balanceEntry = {
        index: 999,
        account: bankAccount.accounting_account.id,
        entry_type: bank_account_type,
        date: transaction.date,
        amount: Math.abs(transaction.amount),
        description: transaction.name,
        added: true,
      };
      const newEntry = generateBlankJournalEntry(
        0,
        Math.abs(transaction.amount),
        oppositeType(bank_account_type)
      );
      setJournalEntries([newEntry, balanceEntry]);
    }
    setVendor(transaction.vendor);
  }, [transaction, generateBlankJournalEntry, bankAccount, bank_account_type]);

  useEffect(() => {
    setTotal(
      journalEntries
        .filter((e) => e.entry_type !== bank_account_type)
        .reduce((sum, entry) => sum + parseFloat(entry.amount || 0), 0)
        .toFixed(2)
    );
  }, [journalEntries, bank_account_type]);

  const addLine = () => {
    const newEntry = generateBlankJournalEntry(
      index,
      0,
      oppositeType(bank_account_type)
    );
    setJournalEntries((prevList) => [...prevList, newEntry]);
    setIndex((prev) => prev + 1);
  };

  const removeLine = (index) => {
    setJournalEntries((prevList) =>
      prevList.filter((entry) => entry.index !== index)
    );
  };

  const changeEntryAmount = (entry, e) => {
    entry.amount = Number(e.target.value).toFixed(2);
    setJournalEntries((prevList) =>
      prevList.map((e) => (e.index === entry.index ? entry : e))
    );
  };

  const changeEntryDescription = (entry, e) => {
    entry.description = e.target.value;
    setJournalEntries((prevList) =>
      prevList.map((e) => (e.index === entry.index ? entry : e))
    );
  };

  const updateVendor = (vendor) => {
    setVendor(vendor.value);
  };

  const updateEntryAccount = (account, { entry }) => {
    entry.account = account.value;
    setJournalEntries((prevList) =>
      prevList.map((e) => (e.index === entry.index ? entry : e))
    );
  };

  const submitSplit = () => {
    if (!vendor) {
      context.fireToast("Error", "Please select vendor.", "error");
      return;
    }
    if (Number(total) !== Math.abs(transaction.amount)) {
      context.fireToast(
        "Error",
        "Split amount doesn't match original amount.",
        "error"
      );
      return;
    }
    if (journalEntries.length === 1) {
      context.fireToast(
        "Error",
        "Add another line to split transaction.",
        "error"
      );
      return;
    }
    for (let entry of journalEntries) {
      if (!entry.account) {
        context.fireToast(
          "Error",
          "Please select account on all lines.",
          "error"
        );
        break;
      }
      if (entry.amount <= 0) {
        context.fireToast("Error", "Invalid amount on line.", "error");
        break;
      }
    }

    var data = journalEntries.map((entry) => {
      var object = {
        customer: customer_id,
        account: entry.account,
        entry_type: entry.entry_type,
        date: entry.date,
        amount: entry.amount,
        description: entry.description,
        added: true,
      };

      if (entry.id) {
        object.id = entry.id;
      }

      return object;
    });

    axios
      .patch(
        `/api/v2/bankaccounts/${transaction.bankaccount}/transactions/${transaction.id}/`,
        {
          vendor: vendor,
          journal_entries: data,
        }
      )
      .then((res) => {
        replaceTransaction(res.data, "remove");
        closeSplitWindow();
        context.fireToast(
          "Success",
          "Transaction split successfully.",
          "success"
        );
      })
      .catch((err) => console.log(err));
  };

  const closeSplitWindow = () => {
    setJournalEntries([]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeSplitWindow} isCentered>
      <ModalOverlay />
      <ModalContent maxH="800px" maxW="1000px">
        <ModalHeader>Split Transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align={"start"}>
            <Text>
              Please input the fields below to this split transaction in it's
              relevant accounts.
            </Text>
            <Flex w="100%">
              <VStack spacing="0px" align={"start"}>
                <Text m="0">Total Amount</Text>
                <Text as="b" fontSize={"4xl"}>
                  {renderAmount(Math.abs(transaction.amount))}
                </Text>
              </VStack>
              <Spacer />
              <VStack spacing="2px" align={"start"}>
                <Text m="0">Vendor</Text>
                <VendorSelect
                  selectedVendorID={vendor}
                  onChange={updateVendor}
                />
              </VStack>
            </Flex>
            <Table variant="unstyled">
              <Thead>
                <Tr>
                  <Th>Account</Th>
                  <Th>Description</Th>
                  <Th>Amount</Th>
                  <Th style={{ width: "118px" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {journalEntries
                  .filter((e) => e.entry_type !== bank_account_type)
                  .map((entry) => (
                    <Tr key={entry.index || 0}>
                      <Td>
                        <DropDown
                          name="account"
                          options={accountList}
                          selectedValue={entry.account}
                          metaData={{ entry }}
                          onChange={updateEntryAccount}
                        />
                      </Td>
                      <Td>
                        <Input
                          type="text"
                          value={entry.description}
                          onChange={(e) => changeEntryDescription(entry, e)}
                        />
                      </Td>
                      <Td>
                        <NumberInput defaultValue={entry.amount}>
                          <NumberInputField
                            onChange={(e) => changeEntryAmount(entry, e)}
                          />
                        </NumberInput>
                      </Td>
                      <Td>
                        <IconButton
                          variant="outline"
                          colorScheme="black"
                          aria-label="Delete line"
                          icon={<Trash2 />}
                          onClick={() => removeLine(entry.index)}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Flex w="100%">
              <VStack align={"start"}>
                <Button
                  rightIcon={<Plus />}
                  variant={"ghost"}
                  onClick={addLine}
                >
                  Add line
                </Button>
              </VStack>
              <Spacer />
              <VStack align={"start"}>
                <HStack>
                  <Text w="150px" align={"right"}>
                    Split Amount:
                  </Text>
                  <Text w="90px">{renderAmount(Math.abs(total))}</Text>
                </HStack>
                <HStack>
                  <Text w="150px" align={"right"}>
                    Original Amount:
                  </Text>
                  <Text w="90px">
                    {renderAmount(Math.abs(transaction.amount))}
                  </Text>
                </HStack>
                <HStack>
                  <Text w="150px" align={"right"}>
                    Difference:
                  </Text>
                  <Text
                    w="90px"
                    color={
                      Number(total) !== Math.abs(transaction.amount)
                        ? "red"
                        : "black"
                    }
                  >
                    {renderAmount(
                      Math.abs(total - Math.abs(transaction.amount))
                    )}
                  </Text>
                </HStack>
              </VStack>
            </Flex>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={closeSplitWindow}>
            Close
          </Button>
          <Button onClick={submitSplit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
