import axios from "axios";

export const fetchData = (url, params = {}, dropdown_format = false) => {
  return axios
    .get(url, {
      headers: { "Content-Type": "application/json" },
      params: params,
      withCredentials: true,
    })
    .then((res) => {
      if (dropdown_format && url.endsWith("/accounts/")) {
        return res.data.map((o) => ({
          value: o.id,
          label: o.number + " - " + o.name,
        }));
      }
      else if (dropdown_format) {
        return res.data.map((o) => ({ value: o.id, label: o.name }));
      } else {
        return res.data;
      }
    })
    .catch((err) => console.log(err));
};
