import {
  Box,
  Card,
  CardBody,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";


import React from "react";

const MetricExpenseCard = ({
  title = "",
  percentage = "0.00",
  total = "0.00",
  perfectPL = "0.00",
  color = "black",
  type = "",
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  return (
    <Stack width="100%" align="center" p="0px 0px">
      <Card
        role="group"
        width="100%"
        height={"143px"}
        borderRadius="12px"
        onClick={() => {
          setBreakdownData({ title: title, type: type });
          triggerExpenseBreakdown(true);
        }}
        _hover={{ boxShadow: "xl", cursor: "pointer" }}
      >
        <CardBody padding={"12px"} >
          <Box align={"left"}>
            <SimpleGrid position="relative">
              <Text
                gridColumn={1}
                gridRow={1}
                fontSize="16px"
                margin={0}
                textAlign="left"
                color={"#202224"}
              >
                {title}
              </Text>
              <Box
                gridColumn={2}
                gridRow={1}
                color="#536DEC"
                textAlign="right"
              >
                <Image
                  src="/metric-expense-arrow.png"
                  alt="Client Navigation Open"
                  boxSize="40px"
                  objectFit="contain"
                  position="absolute"
                  top="50%"
                  right="0"
                  transform="translateY(-50%)"
                  _groupHover={{
                    filter: "brightness(1.2)",
                  }}
                />
              </Box>
            </SimpleGrid>
            <Text fontSize={"28px"} fontWeight={800} margin={0} color={color}>
              {percentage}
            </Text>
          </Box>
          <SimpleGrid
            templateColumns={"14fr 5fr 14fr"}
            align="center"
            p={"5px 0px 0px"}
          >
            <Box
              p={0}
              height={"60px"}
              gridColumn={1}
              gridRow={1}
              textAlign={"left"}
            >
              <Text fontSize={"14px"} margin={0} color={"#202224"}>
                Perfect P&L
              </Text>
              <Text
                fontSize={"clamp(15px, 5vw, 17px)"}
                fontWeight={600}
                margin={0}
                gridColumn={1}
                gridRow={2}
                whiteSpace={"nowrap"}
              >
                Below {perfectPL}
              </Text>
            </Box>
            <Box
              textAlign={"center"}
              align={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <Box
                gridColumn={2}
                gridRow={1}
                height={"50px"}
                border={"1px"}
                width="0px"
                borderColor={"gray.200"}
              ></Box>
            </Box>
            <Box gridColumn={3} gridRow={1} textAlign={"left"}>
              <Text fontSize={"14px"} margin={0} color={"#202224"}>
                Total
              </Text>
              <Text
                fontSize={"clamp(15px, 5vw, 17px)"}
                fontWeight={600}
                margin={0}
                gridColumn={2}
                gridRow={2}
                whiteSpace={"nowrap"}
              >
                {total}
              </Text>
            </Box>
          </SimpleGrid>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default MetricExpenseCard;
