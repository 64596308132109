import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useCurrentProfile } from "../../ContextWrapper";
import { fetchCustomers } from "../../api/customers";
import { AccessToken } from "../../utils";

export const ClientDropDown = ({ customer_id }) => {
  const [customerList, setCustomerList] = useState([]);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const { profile } = useCurrentProfile();

  useEffect(() => {
    let accessToken = AccessToken()
    if (accessToken === null) {
      window.location.href = "/login";
    } else {
      const fetchData = async () => {
        const results = await fetchCustomers(profile, {}, true);
        setCustomerList(results);
        setSelected(results.find((c) => c.value === customer_id));
      };
      fetchData();
    }
  }, [customer_id, profile]);

  return (
    <Box mt={5} w="275px" ml="5">
      {selected && selected.name}
      <Select
        menuPortalTarget={document.body}
        name="client"
        options={customerList}
        value={selected}
        placeholder="Select client"
        onChange={(c) => navigate(`/client/${c.value}`)}
        // size="sm"
        useBasicStyles
        variant="unstyled"
        m="10"
      />
    </Box>
  );
};
