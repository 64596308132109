import { fetchData } from "./fetch";

export const fetchCustomers = (
  profile,
  params = {},
  dropdown_format = false
) => {
  if (profile?.hasAccountingAccess && profile?.type === "accountant") {
    params.having_profile = profile.id;
  } else if (profile?.type === "client") {
    params.having_profile = profile.id;
  }

  return fetchData("/api/v2/customers/", params, dropdown_format);
};
